import { ofType } from "@martin_hotell/rex-tils"
import { LocationChangeAction } from "connected-react-router"
import { ActionsObservable, StateObservable } from "redux-observable"
import { bindCallback, concat, forkJoin, from, merge, of } from "rxjs"
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap
} from "rxjs/operators"

import { auth } from "../../services/firebase/auth"
import { messaging } from "../../services/firebase/messaging"
import { COLORS } from "../../utils/colors"
import * as alertsActions from "../alerts/actions"
import { State } from "../reducers"
import { trackError } from "../tracking/actions"
import { Dependencies } from "../types"
import * as messagingActions from "./actions"
import {
  selectMessaginTopicsInitialized,
  selectMessagingDefaultTopics,
  selectMessagingToken,
  selectMessagingTokenSaved
} from "./selectors"

const getToken$ = from(messaging.getToken())

const getTokenSuccess$ = getToken$.pipe(
  filter(token => !!token),
  map(token => messagingActions.Actions.registerMessagingToken(token))
)

const getTokenFail$ = getToken$.pipe(
  filter(token => !token),
  mergeMap(token => [
    messagingActions.Actions.setMessagingTopicsInitialized(false),
    messagingActions.Actions.setMessagingTokenSaved(false),
    messagingActions.Actions.requestPermission()
  ])
)

export const initializeMessaging = (
  action$: ActionsObservable<LocationChangeAction>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(messagingActions.INITIALIZE_MESSAGING),
    filter(() => !selectMessagingTokenSaved(state$.value)),
    switchMap(() => merge(getTokenSuccess$, getTokenFail$)),
    catchError(error => [
      trackError(error),
      alertsActions.Actions.showSnackbar({
        message: error.message,
        color: COLORS.red
      })
    ])
  )

export const requestPermission = (
  action$: ActionsObservable<LocationChangeAction>
) =>
  action$.pipe(
    ofType(messagingActions.REQUEST_PERMISSION),
    switchMap(() => from(messaging.requestPermission())),
    mapTo(messagingActions.Actions.initializeMessaging()),
    catchError(error => [
      trackError(error),
      alertsActions.Actions.showSnackbar({
        message: error.message,
        color: COLORS.red
      })
    ])
  )

export const initializeTopics = (
  action$: ActionsObservable<messagingActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(messagingActions.INITIALIZE_TOPICS),
    filter(() => !selectMessaginTopicsInitialized(state$.value)),
    switchMap(() => {
      const defaultTopics = selectMessagingDefaultTopics(state$.value)
      const observables = defaultTopics.map(topic =>
        of(messagingActions.Actions.subscribeToTopic(topic))
      )

      return forkJoin(observables).pipe(
        mergeMap(syncObservables => {
          return concat([
            ...syncObservables,
            messagingActions.Actions.setMessagingTopicsInitialized(true)
          ])
        })
      )
    })
  )

const makeOnTokenRefreshObservable = bindCallback(
  messaging.onTokenRefresh.bind(messaging)
)

export const refreshMessagingToken = (
  action$: ActionsObservable<messagingActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(messagingActions.REGISTER_MESSAGING_TOKEN),
    switchMap(() => makeOnTokenRefreshObservable()),
    mergeMap(refreshedToken => [
      messagingActions.Actions.setMessagingTopicsInitialized(false),
      messagingActions.Actions.setMessagingTokenSaved(false),
      messagingActions.Actions.unregisterMessagingToken(
        selectMessagingToken(state$.value)
      ),
      messagingActions.Actions.initializeMessaging()
    ])
  )

export const registerMessagingToken = (
  action$: ActionsObservable<messagingActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(messagingActions.REGISTER_MESSAGING_TOKEN),
    switchMap(({ payload: token }) =>
      from(firebaseApi.addInstanceToken(auth.currentUser.uid, token)).pipe(
        map(result => result.data.token)
      )
    ),
    mergeMap(token => [
      messagingActions.Actions.setMessagingToken(token),
      messagingActions.Actions.setMessagingTokenSaved(true),
      messagingActions.Actions.initializeTopics()
    ])
  )

export const unregisterMessagingToken = (
  action$: ActionsObservable<messagingActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(messagingActions.UNREGISTER_MESSAGING_TOKEN),
    mergeMap(({ payload: token }) =>
      from(firebaseApi.removeInstanceToken(auth.currentUser.uid, token)).pipe(
        map(() => messagingActions.Actions.unsetMessagingToken())
      )
    )
  )

export const subscribeToTopic = (
  action$: ActionsObservable<messagingActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(messagingActions.SUBSCRIBE_TO_TOPIC),
    mergeMap(({ payload: topicName }) => {
      const token = selectMessagingToken(state$.value)
      return from(firebaseApi.subscribeToTopic(token, topicName)).pipe(
        map(response => messagingActions.Actions.addTopic(topicName))
      )
    }),
    catchError(error => [
      trackError(error),
      alertsActions.Actions.showSnackbar({
        message: error.message,
        color: COLORS.red
      })
    ])
  )

export const unsubscribeFromTopic = (
  action$: ActionsObservable<messagingActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(messagingActions.UNSUBSCRIBE_FROM_TOPIC),
    mergeMap(({ payload: topicName }) => {
      const token = selectMessagingToken(state$.value)
      return from(firebaseApi.unsubscribeFromTopic(token, topicName)).pipe(
        map(response => messagingActions.Actions.removeTopic(topicName))
      )
    }),
    catchError(error => [
      trackError(error),
      alertsActions.Actions.showSnackbar({
        message: error.message,
        color: COLORS.red
      })
    ])
  )
