import { connect } from "react-redux"

import LoginScreen from "../screens/LoginScreen"
import * as authActions from "../store/auth/actions"
import { trackScreen } from "../store/tracking/actions"
import { Dispatch } from "../store/types"

const stateToProps = () => ({})
const dispatchToProps = (dispatch: Dispatch) => ({
  onMount: () => dispatch(trackScreen("Login")),
  onLogin: () => dispatch(authActions.Actions.authenticate())
})
export default connect(
  stateToProps,
  dispatchToProps
)(LoginScreen)
