import React, { PureComponent } from "react"
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View
} from "react-native"

import { COLORS } from "../utils/colors"
import { webOnly } from "../utils/style"
import BoldText from "./BoldText"
import Header from "./Header"

const styles = StyleSheet.create({
  root: {},
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 20,
    color: "white",
    backgroundColor: "transparent"
  },
  content: {
    padding: 20
  },
  text: {
    fontFamily: "Roboto Light",
    fontSize: 16,
    lineHeight: 22,
    color: COLORS.text
  },
  link: {
    color: COLORS.green
  },
  input: {
    height: 32,
    marginVertical: 20,
    lineHeight: 16,
    color: COLORS.cyan,
    ...webOnly({
      outline: "none"
    })
  },
  button: {
    margin: 20
  }
})

type Props = {
  onLogin: () => void
}

type State = {
  text: string
}

class LogIn extends PureComponent<Props, State> {
  state = { text: "" }

  render() {
    const { onLogin } = this.props
    return (
      <View style={styles.root}>
        <Header>
          <Text style={styles.title}>Log In</Text>
        </Header>

        <View style={styles.content}>
          <View>
            <Text style={styles.text}>Welcome!</Text>
          </View>

          <TouchableWithoutFeedback onPress={() => onLogin()}>
            <View style={styles.button}>
              <BoldText>Login with LionLogin</BoldText>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    )
  }
}

export default LogIn
