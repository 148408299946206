import { StringMap } from "@martin_hotell/rex-tils"

import { Task } from "../../schemas/tasks"
import * as tasksActions from "./actions"

export type State = Readonly<{
  allIds: Array<string>
  byId: StringMap<Task>
}>

export const initialState: State = {
  byId: {},
  allIds: []
}

const reducer = (
  state: State = initialState,
  action: tasksActions.Actions
): State => {
  switch (action.type) {
    case tasksActions.TASKS_SET: {
      const { payload } = action

      return {
        ...state,
        ...payload
      }
    }

    case tasksActions.TASKS_UNSET: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
