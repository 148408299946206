import moment from "moment"
import { createSelector } from "reselect"

import { DATA_STALE_THRESHOLD } from "../../consts"
import { selectCurrentDate } from "../navigation/selectors"
import { State } from "../reducers"

export const selectDataLastFetched = (state: State): number =>
  state.sources.lastFetched

export const selectDataIsFetching = (state: State): boolean =>
  state.sources.isFetching

export const selectDataFrom = (state: State): number => state.sources.dataFrom
export const selectDataTo = (state: State): number => state.sources.dataTo

export const selectHasData = createSelector(
  selectCurrentDate,
  selectDataFrom,
  selectDataTo,
  (date: number, dataFrom: number, dataTo: number): boolean =>
    dataFrom != null && dataTo != null && date != null
      ? dataFrom <= date && date <= dataTo
      : false
)
export const selectIsDataStale = (state: State): boolean =>
  selectDataLastFetched(state) < +moment.utc().subtract(...DATA_STALE_THRESHOLD)
