import { ofType } from "@martin_hotell/rex-tils"
import { ActionsObservable, StateObservable } from "redux-observable"
import { from, merge, of } from "rxjs"
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap
} from "rxjs/operators"

import { auth } from "../../services/firebase/auth"
import { State } from "../reducers"
import { Dependencies } from "../types"
import * as settingsActions from "./actions"

export const initializeSettings = (
  action$: ActionsObservable<settingsActions.Actions>
) =>
  action$.pipe(
    ofType(settingsActions.INITIALIZE_SETTINGS),
    mapTo(settingsActions.Actions.fetchSettings())
  )

// const fetchSettings$ = (userId: string) =>
//   from(auth.signInWithCustomToken(customToken))
// const loginWithCustomTokenSuccess = (customToken: string) =>
//   loginWithCustomToken(customToken).pipe(
//     filter(user => !!user),
//     mapTo(authActions.Actions.loginSuccess())
//   )
// const loginWithCustomTokenFail = (customToken: string) =>
//   loginWithCustomToken(customToken).pipe(
//     filter(user => !user),
//     mapTo(authActions.Actions.loginFail())
//   )

export const fetchSettings = (
  action$: ActionsObservable<settingsActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(settingsActions.FETCH_SETTINGS),
    mergeMap(() => {
      const fetchSettings$ = from(
        firebaseApi.fetchSettings(auth.currentUser.uid)
      ).pipe(
        map(result => result.data),
        catchError(e => of(null))
      )

      const fetchSettingsSuccess$ = fetchSettings$.pipe(
        filter(result => !!result),
        map(settings => settingsActions.Actions.fetchSettingsSuccess(settings))
      )

      const fetchSettingsFail$ = fetchSettings$.pipe(
        filter(result => !result),
        map(settings => settingsActions.Actions.fetchSettingsFail())
      )

      return merge(fetchSettingsSuccess$, fetchSettingsFail$)
    })
  )

export const fetchSettingsSuccess = (
  action$: ActionsObservable<settingsActions.Actions>
) =>
  action$.pipe(
    ofType(settingsActions.FETCH_SETTINGS_SUCCESS),
    map(settings => settingsActions.Actions.setSettings(settings))
  )
