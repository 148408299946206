import * as React from "react"
import { StyleSheet, Text, TextStyle, ViewStyle } from "react-native"

import { COLORS } from "../utils/colors"
import { webOnly } from "../utils/style"

// import { type TextStyle } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

const styles = StyleSheet.create({
  text: {
    backgroundColor: "transparent",
    fontFamily: "Material Icons",
    fontSize: 24,
    lineHeight: 24,
    color: COLORS.text,
    ...webOnly({
      whiteSpace: "nowrap",
      WebkitFontSmoothing: "antialiased",
      // Support for Safari and Chrome.
      textRendering: "optimizeLegibility",
      // Support for Firefox.
      MozOsxFontSmoothing: "grayscale",
      // Support for IE.
      fontFeatureSettings: "liga"
    } as TextStyle)
  }
})

type Props = {
  style?: ViewStyle
  color?: string
  size?: number
  icon: string
  children?: React.ReactNode
}

export default function Icon(props: Props) {
  const { icon, children, style, color, size, ...other } = props
  return (
    <Text
      {...other}
      style={[
        styles.text,
        style,
        color && { color },
        size && { fontSize: size, lineHeight: size }
      ]}
    >
      {icon || children}
    </Text>
  )
}
