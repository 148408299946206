import React from "react"
import { StyleSheet, View } from "react-native"

import { COLORS } from "../../utils/colors"

const styles = StyleSheet.create({
  line: {
    borderBottomColor: COLORS.lightGrey,
    borderBottomWidth: StyleSheet.hairlineWidth
  }
})

export default function BottomSheetDivider() {
  return <View style={styles.line} />
}
