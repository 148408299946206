import { ImageStyle, Platform, TextStyle, ViewStyle } from "react-native"

/**
 * Apply web platform specific styles
 * @param styles {Object}
 */
export const webOnly = (
  styles: Object
): TextStyle | ViewStyle | ImageStyle | {} =>
  Platform.OS === "web" ? styles : {}
