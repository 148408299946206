import React, { ReactElement, SFC } from "react"
import { StyleSheet, Text, TextStyle } from "react-native"

import { COLORS } from "../utils/colors"

type Props = {
  style?: TextStyle
}

const styles = StyleSheet.create({
  text: {
    fontFamily: "Roboto Condensed",
    fontSize: 15,
    letterSpacing: 0.05 * 15,
    color: COLORS.text
  }
})

const BoldText: SFC<Props> = ({ style, ...other }) => {
  return <Text {...other} style={[styles.text, style]} />
}

export default BoldText
