import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

export const GO_TO_DATE = "navigation__GO_TO_DATE"
export const GO_TO_NEXT_DAY = "navigation__GO_TO_NEXT_DAY"
export const GO_TO_PREV_DAY = "navigation__GO_TO_PREV_DAY"

export const goToDate = (timestamp: number) =>
  createAction(GO_TO_DATE, timestamp)

export const goToPrevDay = () => createAction(GO_TO_PREV_DAY)
export const goToNextDay = () => createAction(GO_TO_NEXT_DAY)

export const Actions = {
  goToDate,
  goToPrevDay,
  goToNextDay
}

export type Actions = ActionsUnion<typeof Actions>
