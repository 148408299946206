import React from "react"
import { StyleSheet, View } from "react-native"

const styles = StyleSheet.create({
  container: {
    flexBasis: 28,
    justifyContent: "center",
    alignItems: "center",
    height: 52
  }
})

export default function ClendarDayEmpty() {
  return <View style={styles.container} />
}
