import { connect } from "react-redux"

import Loading from "../components/Loading"
import { selectDataIsFetching } from "../store/sources/selectors"

const stateToProps = state => {
  return { show: selectDataIsFetching(state) }
}

export default connect(stateToProps)(Loading)
