import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { EntriesFilterBy, EntriesSortBy } from "../../consts"
import { Entry } from "../../schemas/entries"
import { NormalizedData } from "../../schemas/sources"

export const ENTRIES_CREATE = "entries__CREATE"
export const ENTRIES_SET = "entries__SET"
export const ENTRIES_UNSET = "entries__UNSET"
export const SELECTED_ENTRY_SET = "entries.selectedEntry__SET"
export const SELECTED_ENTRY_HOURS_SET = "entries.selectedEntry.hours__SET"
export const SELECTED_ENTRY_UNSET = "entries.selectedEntry__UNSET"
export const ENTRY_HOURS_UPDATE = "entries__ENTRY_HOURS_UPDATE"
export const ENTRY_HOURS_VALIDATE = "entries__ENTRY_HOURS_VALIDATE"
export const ENTRY_HOURS_NOTIFY_NOT_VALID =
  "entries__ENTRY_HOURS_NOTIFY_NOT_VALID"
export const ENTRY_HOURS_SAVE = "entries__ENTRY_HOURS_SAVE"
export const ENTRY_HOURS_UPDATE_WITH_SELECTED =
  "entries__ENTRY_HOURS_UPDATE_WITH_SELECTED"
export const ENTRIES_FILTER_BY_SET = "entries.filterBy__SET"
export const ENTRIES_SORT_BY_SET = "entries.sortBy__SET"
export const ENTRIES_CLEAR_ALL = "entries__ENTRIES_CLEAR_ALL"

export const ENTRY_HOURS_CLEAR = "entries__ENTRY_HOURS_CLEAR"

/**
 * ENTRIES_CREATE
 */
export const createEntries = (
  entries: NormalizedData<string>,
  from: number,
  to: number
) =>
  createAction(ENTRIES_CREATE, {
    entries,
    from,
    to
  })
/**
 * SET_ENTRIES_DATA
 */
export const setEntries = (entries: NormalizedData<string>) =>
  createAction(ENTRIES_SET, entries)
/**
 * UNSET_ENTRIES_DATA
 */
export const unsetEntries = () => createAction(ENTRIES_UNSET)

/**
 * setSelectedEntry
 */
export const setSelectedEntry = (entry: Entry) =>
  createAction(SELECTED_ENTRY_SET, entry)

/**
 * unsetSelectedEntry
 */
export const unsetSelectedEntry = () => createAction(SELECTED_ENTRY_UNSET)

export const setSelectedEntryHours = (hours: number) =>
  createAction(SELECTED_ENTRY_HOURS_SET, hours)

export const updateEntryHours = () => createAction(ENTRY_HOURS_UPDATE)
export const validateEntryHours = () => createAction(ENTRY_HOURS_VALIDATE)
export const notifyNotValidEntryHours = () =>
  createAction(ENTRY_HOURS_NOTIFY_NOT_VALID)

export const saveEntryHours = () => createAction(ENTRY_HOURS_SAVE)

export const updateEntryHoursWithSelected = () =>
  createAction(ENTRY_HOURS_UPDATE_WITH_SELECTED)

export const setEntriesFilterBy = (filterBy: EntriesFilterBy) =>
  createAction(ENTRIES_FILTER_BY_SET, filterBy)

export const setEntriesSortBy = (sortBy: EntriesSortBy) =>
  createAction(ENTRIES_SORT_BY_SET, sortBy)

export const clearAllEntries = () => createAction(ENTRIES_CLEAR_ALL)
export const clearEntryHours = (id: string) =>
  createAction(ENTRY_HOURS_CLEAR, id)

export const Actions = {
  createEntries,
  setEntries,
  unsetEntries,
  setSelectedEntry,
  unsetSelectedEntry,
  setSelectedEntryHours,
  updateEntryHours,
  notifyNotValidEntryHours,
  validateEntryHours,
  saveEntryHours,
  updateEntryHoursWithSelected,
  setEntriesFilterBy,
  setEntriesSortBy,
  clearAllEntries,
  clearEntryHours
}

export type Actions = ActionsUnion<typeof Actions>
