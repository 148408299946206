import { push } from "connected-react-router"
import { connect } from "react-redux"

import BottomBar from "../components/BottomBar/BottomBar"
import { ROUTER_ROUTE_MENU, ROUTER_ROUTE_MORE } from "../consts"
import { goToNextDay, goToPrevDay } from "../store/navigation/actions"
import { selectIsCurrentDateToday } from "../store/navigation/selectors"
import { State } from "../store/reducers"
import { Dispatch } from "../store/types"

const stateToProps = (state: State) => ({
  isToday: selectIsCurrentDateToday(state)
})

const dispatchToProps = (dispatch: Dispatch) => ({
  onMenuPress: () => dispatch(push(ROUTER_ROUTE_MENU)),
  onMorePress: () => dispatch(push(ROUTER_ROUTE_MORE)),
  onPrevDayPress: () => dispatch(goToPrevDay()),
  onNextDayPress: () => dispatch(goToNextDay())
})

export default connect(
  stateToProps,
  dispatchToProps
)(BottomBar)
