import { AlertPayload } from "./schemas/alerts"

export const MESSAGE_DRAG_HINT_DIALOG: AlertPayload = {
  title: "Pro Tip",
  text: "Drag or swipe directly on the row. It's quicker!",
  action: "Got it"
}

export const MESSAGE_CHANGES_SAVED = "Changes saved successfully"

export const MESSAGE_NETWORK_CONNECTION_ERROR =
  "Sorry, there was a network error. Check your connection and try again."

export const MESSAGE_MISSING_ARGUMENT_ERROR = "Missing argument"
export const MESSAGE_HOURS_LIMIT_EXCEDED = "You can only submit up to 24 hrs"
