import { AuthStatus } from "../../services/auth/consts"
import * as authActions from "./actions"

export const initialState = {
  isAuthenticated: false,
  status: AuthStatus.AUTH_STATUS_NONE
}
export type State = typeof initialState

export const authReducer = (
  state: State = initialState,
  action: authActions.Actions
): State => {
  switch (action.type) {
    case authActions.IS_AUTHENTICATED_SET: {
      const { payload: isAuthenticated } = action

      return {
        ...state,
        isAuthenticated
      }
    }
    case authActions.STATUS_SET: {
      const { payload: status } = action

      return {
        ...state,
        status
      }
    }

    default:
      return state
  }
}

export default authReducer
