import React, { PureComponent } from "react"
import { Animated, Dimensions, StyleSheet } from "react-native"

import {
  FLOATING_BUTTON_TYPE_CALENDAR,
  FLOATING_BUTTON_TYPE_CONFIRM,
  FLOATING_BUTTON_TYPE_DAY
} from "../../consts"
import { easeInOut } from "../../utils/easing"
import CalendarFloatingActionButton from "./CalendarFloatingActionButton"
import ConfirmFloatingActionButton from "./ConfirmFloatingActionButton"
import DayFloatingActionButton from "./DayFloatingActionButton"

const styles = StyleSheet.create({
  root: {
    position: "absolute",
    width: "100%",
    height: 56,
    bottom: 28,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
})

const ANIMATION_VALUE_FROM = 0.75

type Props = {
  floatingButtonType: string
}

export default class FloatingActionButtonSwitch extends PureComponent<Props> {
  _inOpacity = new Animated.Value(1)
  _outOpacity = new Animated.Value(ANIMATION_VALUE_FROM)
  _right = new Animated.Value(0)

  componentWillReceiveProps(nextProps: Props) {
    this._animate(nextProps)
  }

  _animate(next: Props) {
    const { floatingButtonType } = this.props
    const nextFloatingButtonType = next.floatingButtonType

    this._inOpacity.stopAnimation()
    this._outOpacity.stopAnimation()

    this._inOpacity.setValue(ANIMATION_VALUE_FROM)
    this._outOpacity.setValue(1)

    if (nextFloatingButtonType !== floatingButtonType) {
      this._animateItem({
        value: this._inOpacity,
        show: true,
        key: nextFloatingButtonType
      })

      this._animateItem({
        value: this._outOpacity,
        show: false,
        key: floatingButtonType
      })

      if (nextFloatingButtonType === FLOATING_BUTTON_TYPE_CONFIRM) {
        Animated.spring(this._right, {
          toValue: 1
        }).start(() => {})
      } else {
        Animated.spring(this._right, {
          toValue: 0
        }).start(() => {})
      }
    }
  }

  _animateItem({ value, show, key }) {
    if (show) this.setState({ [key]: true })
    Animated.timing(value, {
      toValue: 1,
      duration: 250,
      easing: easeInOut
    }).start(() => !show && this.setState({ [key]: false }))
  }

  _getRange = () => ({
    inputRange: [0, 1],
    outputRange: [0, Dimensions.get("window").width / 2 - 28 - 16]
  })

  render() {
    const { floatingButtonType } = this.props

    const calendarOpacity =
      floatingButtonType === FLOATING_BUTTON_TYPE_CALENDAR
        ? this._inOpacity
        : this._outOpacity
    const dayOpacity =
      floatingButtonType === FLOATING_BUTTON_TYPE_DAY
        ? this._inOpacity
        : this._outOpacity
    const confirmOpacity =
      floatingButtonType === FLOATING_BUTTON_TYPE_CONFIRM
        ? this._inOpacity
        : this._outOpacity

    return (
      <Animated.View
        pointerEvents="box-none"
        style={[
          styles.root,
          {
            transform: [
              { translateX: this._right.interpolate(this._getRange()) }
            ]
          }
        ]}
      >
        {floatingButtonType === FLOATING_BUTTON_TYPE_CALENDAR && (
          <Animated.View
            style={[
              {
                opacity: calendarOpacity,
                transform: [
                  { scaleX: calendarOpacity },
                  { scaleY: calendarOpacity }
                ]
              }
            ]}
          >
            <CalendarFloatingActionButton />
          </Animated.View>
        )}
        {floatingButtonType === FLOATING_BUTTON_TYPE_DAY && (
          <Animated.View
            style={[
              {
                opacity: dayOpacity,
                transform: [{ scaleX: dayOpacity }, { scaleY: dayOpacity }]
              }
            ]}
          >
            <DayFloatingActionButton />
          </Animated.View>
        )}
        {floatingButtonType === FLOATING_BUTTON_TYPE_CONFIRM && (
          <Animated.View
            style={[
              {
                opacity: confirmOpacity,
                transform: [
                  { scaleX: confirmOpacity },
                  { scaleY: confirmOpacity }
                ]
              }
            ]}
          >
            <ConfirmFloatingActionButton />
          </Animated.View>
        )}
      </Animated.View>
    )
  }
}
