export type Entry = {
  id: string
  date: number // time in ms
  hours: number
  taskId: string
}

export const emptyEntry = (taskId: string, date: number): Entry => ({
  id: taskId + "-" + date,
  date: date,
  hours: 0,
  taskId
})
