import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

export const ENTRY_UPDATE_TYPE_SET = "ui.entryUpdateType__SET"
export const FLOATING_BUTTON_TYPE_SET = "ui.floatingButtonType__SET"
export const SHOW_USER_CHANGES_SAVED = "ui__SHOW_USER_CHANGES_SAVED"
export const SHOW_DRAG_HINT = "ui__SHOW_DRAG_HINT"
export const SHOW_DRAG_HINT_SET = "ui__showDragHint SET"

export const setEntryUpdateType = (entryUpdateType: string) =>
  createAction(ENTRY_UPDATE_TYPE_SET, entryUpdateType)

export const setFloatingButtonType = (floatingButtonType: string) =>
  createAction(FLOATING_BUTTON_TYPE_SET, floatingButtonType)

export const showUserChangesSaved = () => createAction(SHOW_USER_CHANGES_SAVED)

/**
 * SHOW_DRAG_HINT
 */
export const showDragHint = () => createAction(SHOW_DRAG_HINT)

/**
 * SET_USER_DRAG_HINT
 */
export const setUserDragHint = (payload: boolean = false) =>
  createAction(SHOW_DRAG_HINT_SET, payload)

export const Actions = {
  setEntryUpdateType,
  setFloatingButtonType,
  showUserChangesSaved,
  showDragHint,
  setUserDragHint
}

export type Actions = ActionsUnion<typeof Actions>
