import React, { SFC } from "react"
import { StyleSheet, Text, TextStyle } from "react-native"

import { COLORS } from "../utils/colors"

const styles = StyleSheet.create({
  text: {
    fontFamily: "Roboto Condensed Light",
    fontSize: 14,
    letterSpacing: 0.05 * 14,
    color: COLORS.lightText
  }
})

export type Props = {
  style?: TextStyle
}

const LightText: SFC<Props> = ({ style, ...other }) => {
  return <Text {...other} style={[styles.text, style]} />
}

export default LightText
