import { Entry } from "../../schemas/entries"
import { functions } from "./functions"

export const addInstanceToken = (userId: string, token: string) =>
  functions.httpsCallable("addInstanceToken")({ userId, token })

export const removeInstanceToken = (userId: string, token: string) =>
  functions.httpsCallable("removeInstanceToken")({ userId, token })

export const subscribeToTopic = (token: string, topicName: string) =>
  functions.httpsCallable("subscribeToTopic")({ token, topicName })

export const unsubscribeFromTopic = (token: string, topicName: string) =>
  functions.httpsCallable("unsubscribeFromTopic")({ token, topicName })

export const fetchSettings = (userId: string) =>
  functions.httpsCallable("fetchSettings")({ userId })

export const updateSettings = (userId: string, settings: Object) =>
  functions.httpsCallable("updateSettings")({ userId, settings })

export const fetchSources = (dataFrom: number, dataTo: number) =>
  functions.httpsCallable("fetchSources")({ dataFrom, dataTo })

export const saveEntries = (entries: Entry[]) =>
  functions.httpsCallable("saveEntries")({ entries })
