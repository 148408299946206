import { StringMap } from "@martin_hotell/rex-tils"

import { EntriesFilterBy, EntriesSortBy } from "../../consts"
import { Entry } from "../../schemas/entries"
import * as entriesActions from "./actions"

export type State = Readonly<{
  allIds: Array<string>
  byId: StringMap<Entry>
  selectedEntry: Entry | null
  filterBy: EntriesFilterBy
  sortBy: EntriesSortBy
}>

export const initialState: State = {
  byId: {},
  allIds: [],
  selectedEntry: null,
  filterBy: EntriesFilterBy.SHOW_ALL,
  sortBy: EntriesSortBy.NAME
}

export const reducer = (
  state: State = initialState,
  action: entriesActions.Actions
): State => {
  switch (action.type) {
    case entriesActions.ENTRIES_SET: {
      const { payload } = action

      return {
        ...state,
        ...payload
      }
    }
    case entriesActions.ENTRIES_UNSET: {
      return initialState
    }
    case entriesActions.SELECTED_ENTRY_SET: {
      const { payload: selectedEntry } = action

      return {
        ...state,
        selectedEntry
      }
    }
    case entriesActions.SELECTED_ENTRY_UNSET: {
      return {
        ...state,
        selectedEntry: null
      }
    }
    case entriesActions.SELECTED_ENTRY_HOURS_SET: {
      const { payload: hours } = action

      return {
        ...state,
        selectedEntry: { ...state.selectedEntry, hours }
      }
    }
    case entriesActions.ENTRY_HOURS_UPDATE_WITH_SELECTED: {
      const selectedEntry = state.selectedEntry

      if (selectedEntry) {
        const { id, hours } = selectedEntry
        const updatedEntry = { ...state.byId[id], hours }

        return {
          ...state,
          byId: { ...state.byId, [id]: updatedEntry }
        }
      } else {
        return state
      }
    }
    case entriesActions.ENTRIES_FILTER_BY_SET: {
      const { payload: filterBy } = action

      return {
        ...state,
        filterBy
      }
    }
    case entriesActions.ENTRIES_SORT_BY_SET: {
      const { payload: sortBy } = action

      return {
        ...state,
        sortBy
      }
    }
    case entriesActions.ENTRY_HOURS_CLEAR: {
      const { payload: id } = action
      const clearedEntry = { ...state.byId[id], hours: 0 }

      return {
        ...state,
        byId: { ...state.byId, [id]: clearedEntry }
      }
    }
    default:
      return state
  }
}

export default reducer
