import { connect } from "react-redux"

import TaskListHeader from "../components/TaskListHeader/TaskListHeader"
import { selectRequiredHoursForDate } from "../store/days/selectors"
import {
  selectEntriesHoursForCurrentDate,
  selectSelectedEntryHours,
  selectSelectedEntryOriginalHours,
  selectSelectedEntryProjectColor,
  selectSelectedEntryTaskName
} from "../store/entries/selectors"
import { selectCurrentDate } from "../store/navigation/selectors"
import { selectEntryUpdateType } from "../store/ui/selectors"
import { DEFAULT_HEADER_COLOR } from "../utils/colors"

const stateToProps = state => {
  return function(state) {
    return {
      date: selectCurrentDate(state),
      selectedEntryTaskName: selectSelectedEntryTaskName(state),
      selectedEntryHours: selectSelectedEntryHours(state),
      selectedEntryOriginalHours: selectSelectedEntryOriginalHours(state),
      totalHours: selectEntriesHoursForCurrentDate(state),
      requiredHours: selectRequiredHoursForDate(state),
      updateType: selectEntryUpdateType(state),
      mainColor: DEFAULT_HEADER_COLOR,
      projectColor: selectSelectedEntryProjectColor(state)
    }
  }
}

export default connect(stateToProps)(TaskListHeader)
