import React, { PureComponent } from "react"
import { Animated, StyleSheet, View } from "react-native"

import { ENTRY_UPDATE_TYPE_NONE } from "../../consts"
import { getColorString } from "../../utils/colors"
import { getDayMonthDate } from "../../utils/days"
import { easeOut } from "../../utils/easing"
import ColorBar from "../ColorBar"
import Header from "../Header"
import HeaderText from "../HeaderText"
import HoursMinutesLarge from "../HoursMinutesLarge"

type Props = {
  date: number
  updateType: string
  selectedEntryHours: number
  selectedEntryOriginalHours: number
  selectedEntryTaskName: string
  mainColor: string
  projectColor: string
  totalHours: number
  requiredHours: number
}

const styles = StyleSheet.create({
  root: {
    height: 130
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  content: {
    flex: 1,
    padding: 16,
    justifyContent: "center",
    alignItems: "center"
  },
  bar: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 4,
    height: 4
  }
})

class TaskListHeader extends PureComponent<Props> {
  _totalOpacity = new Animated.Value(
    this.props.updateType !== ENTRY_UPDATE_TYPE_NONE ? 0 : 1
  )
  _updatingOpacity = new Animated.Value(
    this.props.updateType !== ENTRY_UPDATE_TYPE_NONE ? 1 : 0
  )

  // componentWillReceiveProps(nextProps: Props) {
  //   this._animate(nextProps);
  // }

  render() {
    const {
      selectedEntryHours,
      selectedEntryOriginalHours,
      projectColor,
      requiredHours,
      totalHours,
      selectedEntryTaskName,
      date
    } = this.props

    const activeColor =
      this.props.updateType !== ENTRY_UPDATE_TYPE_NONE
        ? getColorString(projectColor)
        : null

    const hours =
      this.props.updateType === ENTRY_UPDATE_TYPE_NONE
        ? totalHours
        : selectedEntryHours

    const text =
      this.props.updateType === ENTRY_UPDATE_TYPE_NONE
        ? getDayMonthDate(date)
        : selectedEntryTaskName

    const progress =
      this.props.updateType === ENTRY_UPDATE_TYPE_NONE
        ? totalHours / requiredHours
        : (totalHours + selectedEntryHours - selectedEntryOriginalHours) /
          requiredHours

    return (
      <Header primary={activeColor} style={styles.root}>
        <View style={styles.container}>
          <View style={styles.content}>
            <HoursMinutesLarge hours={hours} />
            <HeaderText>{text}</HeaderText>
          </View>
          <ColorBar style={styles.bar} progress={progress} color={"white"} />
        </View>
      </Header>
    )
  }

  _animate(next: Props) {
    const { updateType } = this.props
    const nextUpdateType = next.updateType

    if (nextUpdateType !== updateType) {
      if (nextUpdateType === ENTRY_UPDATE_TYPE_NONE) {
        this._animateItem({
          value: this._totalOpacity,
          show: nextUpdateType === ENTRY_UPDATE_TYPE_NONE,
          key: "total"
        })
      } else {
        this._totalOpacity.stopAnimation()
      }

      if (nextUpdateType !== ENTRY_UPDATE_TYPE_NONE) {
        this._animateItem({
          value: this._updatingOpacity,
          show: nextUpdateType !== ENTRY_UPDATE_TYPE_NONE,
          key: "updating"
        })
      } else {
        this._updatingOpacity.stopAnimation()
      }
    }
  }

  _animateItem({
    value,
    show,
    key
  }: {
    value: Animated.Value
    show: boolean
    key: string
  }) {
    if (show) this.setState({ [key]: true })
    Animated.timing(value, {
      toValue: show ? 1 : 0,
      duration: 400,
      easing: easeOut
    }).start(() => !show && this.setState({ [key]: false }))
  }
}

export default TaskListHeader
