import React, { SFC } from "react"
import { ViewStyle } from "react-native"

import BoldText from "./BoldText"
import FadeIn from "./FadeIn"
import FullPanel from "./FullPanel"

const container: ViewStyle = {
  justifyContent: "center",
  alignItems: "center"
}

type Props = {
  show: boolean
}

// export default function Loading(props: Props) {
//   return (
//     <FadeIn in={props.show}>
//       <FullPanel style={container}>
//         <BoldText>Loading</BoldText>
//       </FullPanel>
//     </FadeIn>
//   )
// }

const Loading: SFC<Props> = ({ show }) => {
  return (
    <FadeIn in={show}>
      <FullPanel style={container}>
        <BoldText>Loading</BoldText>
      </FullPanel>
    </FadeIn>
  )
}

export default Loading
