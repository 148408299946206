import { User } from "../../schemas/user"
import * as userActions from "./actions"

export const initialState: User = {
  displayName: "",
  contractHours: 0,
  timesheetsCompletedUntil: new Date().getTime(),
  workingPattern: [],
  absenceRequests: [],
  calendarExceptions: [],
  companyCalendarExceptions: [],
  requiredDailyHours: 0
}
export type State = typeof initialState

const reducer = (
  state: State = initialState,
  action: userActions.Actions
): State => {
  switch (action.type) {
    case userActions.USER_SET: {
      const { payload } = action

      return {
        ...state,
        ...payload
      }
    }

    case userActions.USER_UNSET: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
