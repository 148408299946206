import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

type LinkProps = {
  url: string
  label: string
}

export const OPEN_LINK = "general__OPEN_LINK"

export const openLink = ({ url, label }: LinkProps) =>
  createAction(OPEN_LINK, { url, label })

export const Actions = {
  openLink
}

export type Actions = ActionsUnion<typeof Actions>
