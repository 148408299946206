import * as React from "react"
import { StyleSheet, View } from "react-native"

import { COLORS } from "../../utils/colors"
import { createShadowElevation } from "../../utils/elevation"
import Icon from "../Icon"
import BottomBarButton from "./BottomBarButton"

const styles = StyleSheet.create({
  root: {
    bottom: 0,
    height: 56,
    backgroundColor: "white",

    ...createShadowElevation(8, true),

    display: "flex",
    flexDirection: "row",
    padding: 16
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1
  },
  leftContainer: {
    justifyContent: "space-between"
  },
  rightContainer: {
    justifyContent: "space-between"
  },
  spacing: {
    marginHorizontal: 48
  }
})

type Props = {
  onMenuPress: () => void
  onMorePress: () => void
  onPrevDayPress: () => void
  onNextDayPress: () => void
  isToday: boolean
}

export default class BottomBar extends React.PureComponent<Props> {
  _onMenuPress = () => {
    this.props.onMenuPress()
  }

  _onMorePress = () => {
    this.props.onMorePress()
  }

  _onPrevDayPress = () => {
    this.props.onPrevDayPress()
  }

  _onNextDayPress = () => {
    this.props.onNextDayPress()
  }

  render() {
    const { isToday } = this.props

    return (
      <View style={styles.root}>
        <View style={[styles.container, styles.leftContainer]}>
          <BottomBarButton onPress={this._onMenuPress}>
            <Icon size={24} icon="menu" color={COLORS.lightText} />
          </BottomBarButton>
          <BottomBarButton
            onPress={this._onPrevDayPress}
            style={styles.spacing}
          >
            <Icon size={24} icon="keyboard_arrow_left" />
          </BottomBarButton>
        </View>
        <View style={[styles.container, styles.rightContainer]}>
          <BottomBarButton
            onPress={this._onNextDayPress}
            disabled={isToday}
            style={styles.spacing}
          >
            <Icon
              size={24}
              icon="keyboard_arrow_right"
              color={isToday ? COLORS.lightGrey : COLORS.text}
            />
          </BottomBarButton>
          <BottomBarButton onPress={this._onMorePress}>
            <Icon size={24} icon="more_vert" color={COLORS.lightText} />
          </BottomBarButton>
        </View>
      </View>
    )
  }
}
