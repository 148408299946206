import { isToday } from "../../utils/days"
import { State } from "../reducers"

/**
 * Returns current date
 */
export const selectCurrentDate = (state: State) => state.navigation.date

export const selectIsCurrentDateToday = (state: State) =>
  isToday(state.navigation.date)
