import { State } from "../reducers"

export const selectMessagingToken = (state: State) => state.messaging.token
export const selectMessagingTokenSaved = (state: State) =>
  state.messaging.tokenSaved
export const selectMessagingTopics = (state: State) => state.messaging.topics
export const selectMessagingDefaultTopics = (state: State) =>
  state.messaging.defaultTopics
export const selectMessaginTopicsInitialized = (state: State) =>
  state.messaging.topicsInitialized
export const selectHasTopic = (state: State, topicName: string) =>
  state.messaging.topics.find(t => t.name === topicName) !== undefined
