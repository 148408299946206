import React from "react"
import { StyleSheet, View } from "react-native"

import BodyText from "../BodyText"
import HoursMinutes from "../HoursMinutes"

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    paddingHorizontal: 20
  }
})

type Props = { taskName: string; hours: number }

export default function TaskLabel({ taskName, hours }: Props) {
  return (
    <View style={styles.root} pointerEvents="none">
      <BodyText>{taskName}</BodyText>
      <HoursMinutes hours={hours} />
    </View>
  )
}
