import { StringMap } from "@martin_hotell/rex-tils"
import { createSelector } from "reselect"

import {
  ADMIN_PROJECT_ID,
  EntriesFilterBy,
  EntriesSortBy,
  MAX_HOURS_PER_DAY
} from "../../consts"
import { Entry } from "../../schemas/entries"
import { Project } from "../../schemas/projects"
import { Task } from "../../schemas/tasks"
import { selectCurrentDate } from "../navigation/selectors"
import { selectProjects } from "../projects/selectors"
import { State } from "../reducers"
import { selectTasks } from "../tasks/selectors"

const selectAllIds = (state: State) => state.entries.allIds
const selectById = (state: State) => state.entries.byId
const selectId = (state: State, id: string) => id

export const selectEntries = (state: State) => selectById(state)

/**
 * Get selected entry
 */
export const selectSelectedEntry = (state: State) => {
  return state.entries.selectedEntry
}

export const selectSelectedEntryHours = createSelector(
  selectSelectedEntry,
  selectedEntry => (selectedEntry && selectedEntry.hours) || 0
)

export const selectSelectedEntryProjectColor = createSelector(
  selectSelectedEntry,
  selectTasks,
  selectProjects,
  (selectedEntry, tasks, projects) =>
    selectedEntry && projects[tasks[selectedEntry.taskId].projectId].color
)

export const selectSelectedEntryTaskName = createSelector(
  selectSelectedEntry,
  selectTasks,
  (selectedEntry, tasks) => selectedEntry && tasks[selectedEntry.taskId].name
)

export const selectSelectedEntryOriginalHours = createSelector(
  selectSelectedEntry,
  selectById,
  (selectedEntry, byId) => (selectedEntry && byId[selectedEntry.id].hours) || 0
)

/**
 * For given id return either the entry or selected entry if ids are equal
 */
export const selectActiveEntryById = createSelector(
  selectById,
  selectId,
  selectSelectedEntry,
  (byId, id, selectedEntry) =>
    selectedEntry && selectedEntry.id === id ? selectedEntry : byId[id]
)

/**
 * Returs an array with entries' ids for given date
 */
export const selectEntryIdsByCurrentDate = createSelector(
  selectAllIds,
  selectById,
  selectCurrentDate,
  (allIds, byId, date) => allIds.filter(entryId => byId[entryId].date === date)
)

/**
 * Returs an array with entries for given date
 */
export const selectEntriesByCurrentDate = createSelector(
  selectById,
  selectEntryIdsByCurrentDate,
  (byId, entryIds) => entryIds.map(entryId => byId[entryId])
)

const sortByName = (tasks: StringMap<Task>, projects: StringMap<Project>) => (
  a: Entry,
  b: Entry
) => {
  const taskA = tasks[a.taskId]
  const taskB = tasks[b.taskId]
  const projectA = projects[taskA.projectId]
  const projectB = projects[taskB.projectId]
  // override sorting rules for admin task
  if (taskA.projectId === ADMIN_PROJECT_ID || projectA.name < projectB.name) {
    return -1
  } else if (
    taskB.projectId === ADMIN_PROJECT_ID ||
    projectA.name > projectB.name
  ) {
    return 1
  } else {
    // sort by task name if in the same project
    if (taskA.name < taskB.name) {
      return -1
    } else if (taskB.name < taskA.name) {
      return 1
    } else {
      return 0
    }
  }
}

export const selectEntriesFilterBy = (state: State) => state.entries.filterBy
export const selectEntriesSortBy = (state: State) => state.entries.sortBy

/**
 * Returs an array with entries for given date joined with project ids
 */
export const selectEntriesWithProjectIdsByCurrentDate = createSelector(
  selectEntriesByCurrentDate,
  selectTasks,
  selectProjects,
  selectEntriesFilterBy,
  selectEntriesSortBy,
  (entries, tasks, projects, filterBy, sortBy) => {
    return entries
      .filter(
        (filterBy => {
          switch (filterBy) {
            case EntriesFilterBy.SHOW_ALL:
              return () => true
            case EntriesFilterBy.SHOW_WITH_HOURS:
              return entry => entry.hours > 0
            default:
              return () => true
          }
        })(filterBy)
      )
      .sort(
        (sortBy => {
          switch (sortBy) {
            case EntriesSortBy.NAME:
            case EntriesSortBy.RECENT:
            default:
              return sortByName(tasks, projects)
          }
        })(sortBy)
      )
      .map(entry => ({
        id: entry.id,
        projectId: tasks[entry.taskId].projectId
      }))
  }
)
/**
 * Returs an array with projects' ids for given date
 */
export const selectProjectIdsByCurrentDate = createSelector(
  selectEntriesWithProjectIdsByCurrentDate,
  entries => Array.from(new Set(entries.map(entry => entry.projectId)))
)

/**
 * Returns an array with all entries' dates
 */
export const selectEntryDates = createSelector(
  selectById,
  byId => Object.values(byId).map(entry => entry.date)
)

/**
 * Returns number of submitted hours for current date
 */
export const selectEntriesHoursForCurrentDate = createSelector(
  selectEntriesByCurrentDate,
  entries => entries.reduce((acc, entry) => (acc += entry.hours), 0)
)

/**
 * Has selected entry hours changed
 */
export const selectUpdatingHoursHaveChanged = createSelector(
  selectSelectedEntryHours,
  selectSelectedEntryOriginalHours,
  (selectedEntryHours, selectedEntryOriginalHours) =>
    selectedEntryHours !== selectedEntryOriginalHours
)

export const selectAreHoursValidBeforeUpdateForCurrentDate = createSelector(
  selectEntriesHoursForCurrentDate,
  selectSelectedEntryHours,
  selectSelectedEntryOriginalHours,
  (entriesHours, selectedEntryHours, selectedEntryOriginalHours) =>
    entriesHours - selectedEntryOriginalHours + selectedEntryHours <=
    MAX_HOURS_PER_DAY
)

export const selectEntriesWithSubmittedHoursByCurrentDate = createSelector(
  selectEntriesByCurrentDate,
  entries => entries.filter(entry => entry.hours > 0)
)
