import React, { Component } from "react"
import {
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View
} from "react-native"

import { ENTRY_UPDATE_TYPE_INPUT } from "../consts"
import { Entry } from "../schemas/entries"
import { COLORS } from "../utils/colors"
import { formatDoubleDigits } from "../utils/numbers"
import BodyText from "./BodyText"
import Modal from "./Modal"

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject
  },
  transition: {
    alignSelf: "stretch",
    justifyContent: "flex-end",
    alignItems: "stretch",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  content: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "stretch",
    backgroundColor: COLORS.lightGrey
  },
  column: {
    flex: 1,
    alignItems: "stretch"
  },
  option: {
    alignItems: "center",
    justifyContent: "center",
    height: 45,
    backgroundColor: "white",
    borderColor: "rgba(0,0,0,0.05)",
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderRightWidth: StyleSheet.hairlineWidth
  },
  optionSelected: {
    backgroundColor: COLORS.lightGrey
  }
})

type Props = {
  onUpdate: (hours: number) => void
  selectedEntry: Entry
  updateType: string
}

type State = {
  hour: number
  minute: number
  show: boolean
}

export default class HoursMinutesInput extends Component<Props, State> {
  state = {
    hour: this.props.selectedEntry
      ? Math.floor(this.props.selectedEntry.hours)
      : 0,
    minute: this.props.selectedEntry ? this.props.selectedEntry.hours % 1 : 0,
    show: !!this.props.selectedEntry
  }

  _scroll = null

  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.selectedEntry !== this.props.selectedEntry ||
      nextProps.updateType !== this.props.updateType
    )
  }

  componentDidMount() {
    if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0, animated: false })
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.selectedEntry &&
      nextProps.selectedEntry !== this.props.selectedEntry
    ) {
      const { hours } = nextProps.selectedEntry
      this.setState({ hour: Math.floor(hours), minute: hours % 1 })
    }
  }

  _selectHour = (hour: number) => this.setState({ hour }, this._update)

  _selectMinute = (minute: number) => this.setState({ minute }, this._update)

  _update = () => this.props.onUpdate(this.state.hour + this.state.minute)

  _renderHour = (i: number) => {
    const { hour } = this.state
    return (
      <TouchableWithoutFeedback key={i} onPress={() => this._selectHour(i)}>
        <View style={[styles.option, i === hour && styles.optionSelected]}>
          <BodyText style={{ fontSize: 16 }}>{formatDoubleDigits(i)}</BodyText>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  _renderMinute = (i: number) => {
    const value = i / 4
    const { minute } = this.state
    return (
      <TouchableWithoutFeedback
        key={i}
        onPress={() => this._selectMinute(value)}
      >
        <View
          style={[styles.option, value === minute && styles.optionSelected]}
        >
          <BodyText style={{ fontSize: 16 }}>
            {formatDoubleDigits(value * 60)}
          </BodyText>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  render() {
    return (
      <Modal
        contentStyle={StyleSheet.flatten(styles.transition)}
        in={this.props.updateType === ENTRY_UPDATE_TYPE_INPUT}
        timing={{ enter: 200, exit: 200 }}
      >
        <ScrollView
          contentContainerStyle={styles.content}
          ref={c => (this._scroll = c)}
        >
          <View style={styles.column}>
            {Array.from({ length: 25 }, (v, i) => i).map(this._renderHour)}
          </View>
          <View style={styles.column}>
            {Array.from({ length: 4 }, (v, i) => i).map(this._renderMinute)}
          </View>
        </ScrollView>
      </Modal>
    )
  }
}
