import {
  ENTRY_UPDATE_TYPE_NONE,
  FLOATING_BUTTON_TYPE_CALENDAR
} from "../../consts"
import * as uiActions from "./actions"

export const initialState = {
  entryUpdateType: ENTRY_UPDATE_TYPE_NONE,
  floatingButtonType: FLOATING_BUTTON_TYPE_CALENDAR,
  showDragHint: true
}
export type State = typeof initialState

export const reducer = (
  state: State = initialState,
  action: uiActions.Actions
): State => {
  switch (action.type) {
    case uiActions.ENTRY_UPDATE_TYPE_SET: {
      const { payload: entryUpdateType } = action

      return {
        ...state,
        entryUpdateType
      }
    }
    case uiActions.FLOATING_BUTTON_TYPE_SET: {
      const { payload: floatingButtonType } = action

      return {
        ...state,
        floatingButtonType
      }
    }

    case uiActions.SHOW_DRAG_HINT_SET: {
      const { payload: showDragHint } = action

      return {
        ...state,
        showDragHint
      }
    }

    default:
      return state
  }
}

export default reducer
