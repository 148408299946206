import { push } from "connected-react-router"
import React from "react"
import { connect } from "react-redux"

import { FLOATING_BUTTON_TYPE_DAY, ROUTER_ROUTE_CALENDAR } from "../../consts"
import { Dispatch } from "../../store/types"
import { setFloatingButtonType } from "../../store/ui/actions"
import Icon from "../Icon"
import FloatingActionButton from "./FloatingActionButton"

type Props = {
  onPress: () => void
}

function CalendarFloatingActionButton(props: Props) {
  return (
    <FloatingActionButton onPress={props.onPress}>
      <Icon size={30} color="white" icon="date_range" />
    </FloatingActionButton>
  )
}

const stateToProps = state => ({})

const dispatchToProps = (dispatch: Dispatch) => ({
  onPress: () => {
    dispatch(push(ROUTER_ROUTE_CALENDAR))
    dispatch(setFloatingButtonType(FLOATING_BUTTON_TYPE_DAY))
  }
})

export default connect(
  stateToProps,
  dispatchToProps
)(CalendarFloatingActionButton)
