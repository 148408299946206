import React, { PureComponent } from "react"
import { ScrollView, StyleSheet } from "react-native"

import EmptyDay from "./EmptyDay"
import Task from "./Task/Task"
import TaskListSubheader from "./TaskListSubheader"

type TapHandler = () => void

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "stretch",
    height: 0 // so that scroll works
  },
  scroll: {
    paddingBottom: 44
  }
})

type Props = {
  entries: Array<{ id: string; projectId: string }>
  hasData: boolean
}

class TaskList extends PureComponent<Props> {
  _isScrolling: boolean = false
  _scrollingTimeout: number

  _tapHandler: TapHandler = null

  _renderItem = (entryId: string) => (
    <Task
      key={entryId}
      entryId={entryId}
      registerTapHandler={this.registerTapHandler}
    />
  )

  _renderItems = () => {
    const { entries } = this.props

    const projectsFromEntries = Array.from(
      new Set(entries.map(({ projectId }) => projectId))
    )

    return projectsFromEntries.map(projectId => {
      const nestedItems = entries
        .filter(entry => entry.projectId === projectId)
        .map(entry => this._renderItem(entry.id))

      return [
        <TaskListSubheader key={`${projectId}`} projectId={projectId} />,
        ...nestedItems
      ]
    })
  }

  registerTapHandler = (handler: TapHandler) => (this._tapHandler = handler)

  _handleTap = () =>
    typeof this._tapHandler === "function" &&
    !this._isScrolling &&
    this._tapHandler()

  _onScroll = () => {
    this._isScrolling = true
    if (this._scrollingTimeout) clearTimeout(this._scrollingTimeout)
    this._scrollingTimeout = window.setTimeout(() => {
      this._isScrolling = false
    }, 300)
  }

  render() {
    const { hasData } = this.props

    if (!hasData) return <EmptyDay />
    return (
      <ScrollView
        style={styles.scroll}
        onTouchEnd={this._handleTap}
        scrollEventThrottle={16}
        onScroll={this._onScroll}
      >
        {this._renderItems()}
      </ScrollView>
    )
  }
}

export default TaskList
