import { createSelector } from "reselect"

import { Task } from "../../schemas/tasks"
import { State } from "../reducers"

const selectAllIds = (state: State) => state.tasks.allIds
const selectById = (state: State) => state.tasks.byId
const selectId = (state: State, id: string) => id

/**
 * Returns task by given id
 */
export const selectTaskById = createSelector(
  selectById,
  selectId,
  (byId, id): Task => byId[id]
)

/**
 * Retursn all tasks
 */
export const selectTasks = (state: State) => selectById(state)

/**
 * Returns an array of tasks' ids for given date
 */
export const selectTasksIdsByDate = (
  state: State,
  date: number
): Array<string> =>
  selectAllIds(state).reduce((acc: Array<string>, id: string) => {
    const task: Task = selectTasks(state)[id]

    return task.startDate <= date && task.dueDate >= date
      ? [...acc, task.id]
      : acc
  }, [])
