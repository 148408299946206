import { ofType } from "@martin_hotell/rex-tils"
import { ActionsObservable, StateObservable } from "redux-observable"
import { concat, from, of } from "rxjs"
import { catchError, filter, map, mapTo, mergeMap } from "rxjs/operators"

import { COLORS } from "../../utils/colors"
import { getToday } from "../../utils/days"
import * as alertsActions from "../alerts/actions"
import * as daysActions from "../days/actions"
import * as entriesActions from "../entries/actions"
import * as projectsActions from "../projects/actions"
import { State } from "../reducers"
import * as tasksActions from "../tasks/actions"
import { trackError } from "../tracking/actions"
import { Dependencies } from "../types"
import * as userActions from "../user/actions"
import { selectEarliestDate } from "../user/selectors"
import * as sourcesActions from "./actions"
import { selectDataFrom, selectHasData, selectIsDataStale } from "./selectors"

/**
 * Refresh stale data
 */
export const checkIfFresh = (
  action$: ActionsObservable<sourcesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(sourcesActions.CHECK_IF_FRESH),
    filter(({ payload: forceRefresh }) => {
      const isStale = forceRefresh || selectIsDataStale(state$.value)
      const hasData = selectHasData(state$.value)

      return isStale || !hasData
    }),
    mapTo(sourcesActions.Actions.refresh())
  )

/**
 * Refresh stale data
 */
export const refreshSources = (
  action$: ActionsObservable<sourcesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(sourcesActions.REFRESH),
    map(() => {
      const from =
        selectDataFrom(state$.value) || selectEarliestDate(state$.value)
      const to = getToday()

      return sourcesActions.Actions.fetch(from, to)
    })
  )

/**
 * Fetch all user and tasks data
 * @param {ActionsObservable} action$
 * @param {Store} store
 */
export const fetchSources = (
  action$: ActionsObservable<sourcesActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(sourcesActions.FETCH),
    mergeMap(action => {
      const { dataFrom, dataTo } = action.payload

      const requestObservable = from(
        firebaseApi.fetchSources(dataFrom, dataTo)
      ).pipe(
        map(response => ({
          user: response.data.user,
          tasks: response.data.tasks,
          projects: response.data.projects,
          entries: response.data.entries
        })),
        mergeMap(({ user, tasks, projects, entries }) =>
          concat(
            of(userActions.setUser(user)),
            of(tasksActions.Actions.setTasks(tasks)),
            of(projectsActions.Actions.setProjects(projects)),
            of(entriesActions.Actions.createEntries(entries, dataFrom, dataTo)),
            of(daysActions.Actions.createDays()),
            of(sourcesActions.Actions.setLastFetched(Date.now())),
            of(sourcesActions.Actions.setDataFrom(dataFrom)),
            of(sourcesActions.Actions.setDataTo(dataTo))
          )
        )
      )

      return concat(
        of(sourcesActions.Actions.setIsFetching(true)),
        requestObservable,
        of(sourcesActions.Actions.setIsFetching(false))
      )
    }),

    catchError(error => [
      trackError(error),
      sourcesActions.Actions.setIsFetching(false),
      alertsActions.Actions.showSnackbar({
        message: error.message,
        color: COLORS.red
      })
    ])
  )
