import ReactGA from "react-ga"

import { gaTrackingID, isProduction } from "../../../config"

ReactGA.initialize(gaTrackingID, {
  debug: false /*!isProduction*/,
  titleCase: false
})

// custom dimension for platform
ReactGA.set({ dimension1: "Web" })

export default ReactGA
