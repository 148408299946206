import { combineEpics } from "redux-observable"

import {
  authState,
  authenticate,
  completePartialLogin,
  login,
  loginAfterAuthenticate,
  logout
} from "./auth/epics"
import { createDays } from "./days/epics"
import {
  cancelUpdateEntry,
  clearAllEntries,
  createEntries,
  notifyNotValidEntriesHours,
  updateEntry,
  validateEntriesHouresBeforeUpdate,
  validateEntriesHours
} from "./entries/epics"
import { openLink } from "./general/epics"
import {
  initializeMessaging,
  initializeTopics,
  refreshMessagingToken,
  registerMessagingToken,
  requestPermission,
  subscribeToTopic,
  unregisterMessagingToken,
  unsubscribeFromTopic
} from "./messaging/epics"
import {
  goToNextDay,
  goToPrevDay,
  navigateToDay,
  navigateToToday
} from "./navigation/epics"
import {
  fetchSettings,
  fetchSettingsSuccess,
  initializeSettings
} from "./settings/epics"
import { checkIfFresh, fetchSources, refreshSources } from "./sources/epics"
import { showDragHint, showUserChangesSaved } from "./ui/epics"

const rootEpic = combineEpics(
  // Auth
  authenticate,
  loginAfterAuthenticate,
  login,
  // loginSuccess,
  completePartialLogin,
  logout,
  authState,
  // Sources
  checkIfFresh,
  refreshSources,
  fetchSources,
  // Ui
  showDragHint,
  showUserChangesSaved,
  createEntries,
  clearAllEntries,
  createDays,
  openLink,
  // Messaging
  initializeMessaging,
  requestPermission,
  refreshMessagingToken,
  registerMessagingToken,
  unregisterMessagingToken,
  initializeTopics,
  subscribeToTopic,
  unsubscribeFromTopic,
  // Navigation
  navigateToDay,
  navigateToToday,
  validateEntriesHouresBeforeUpdate,
  updateEntry,
  validateEntriesHours,
  notifyNotValidEntriesHours,
  cancelUpdateEntry,
  goToNextDay,
  goToPrevDay,

  // Settings
  initializeSettings,
  fetchSettings,
  fetchSettingsSuccess
)

export default rootEpic
