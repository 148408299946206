import { ofType } from "@martin_hotell/rex-tils"
import { ActionsObservable, StateObservable } from "redux-observable"
import { filter, map, mergeMap } from "rxjs/operators"

import { MESSAGE_CHANGES_SAVED, MESSAGE_DRAG_HINT_DIALOG } from "../../locale"
import * as alertsActions from "../alerts/actions"
import { State } from "../reducers"
import { trackEvent } from "../tracking/actions"
import * as uiActions from "./actions"
import { selectShowDragHint } from "./selectors"

/**
 * Show changes saved message
 * @param {ActionsObservable} action$
 */
export const showUserChangesSaved = (
  action$: ActionsObservable<uiActions.Actions>
) =>
  action$.pipe(
    ofType(uiActions.SHOW_USER_CHANGES_SAVED),
    map(() =>
      alertsActions.Actions.showSnackbar({ message: MESSAGE_CHANGES_SAVED })
    )
  )

/**
 * Show drag interaction hint
 */
export const showDragHint = (
  action$: ActionsObservable<uiActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(uiActions.SHOW_DRAG_HINT),
    filter(() => selectShowDragHint(state$.value) === true),
    mergeMap(() => [
      alertsActions.Actions.showAlert(MESSAGE_DRAG_HINT_DIALOG),
      trackEvent({
        category: "Hint",
        action: "Show Drag Hint",
        nonInteraction: true
      }),
      uiActions.Actions.setUserDragHint()
    ])
  )
