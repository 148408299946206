import { connect } from "react-redux"

import FloatingActionButtonSwitch from "../components/FloatingActionButton/FloatingActionButtonSwitch"
import { selectFloatingButtonType } from "../store/ui/selectors"

const stateToProps = state => ({
  floatingButtonType: selectFloatingButtonType(state)
})

export default connect(stateToProps)(FloatingActionButtonSwitch)
