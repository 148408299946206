import React, { PureComponent, ReactNode } from "react"
import { StyleSheet, Text, View } from "react-native"

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: 72,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 12
  },
  text: {
    fontFamily: "Roboto Condensed",
    fontSize: 14
  }
})

type Props = {
  name: string
  children: ReactNode
}

export default class CalendarMonth extends PureComponent<Props> {
  render() {
    return (
      <View>
        <View style={styles.container}>
          <Text style={styles.text}>{this.props.name.toUpperCase()}</Text>
        </View>
        {this.props.children}
      </View>
    )
  }
}
