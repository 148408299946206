export const createShadowElevation = (
  elevation: number,
  invert: boolean = false
) => ({
  shadowColor: "black",
  shadowOpacity: 0.0015 * elevation + 0.18,
  shadowRadius: 0.54 * elevation,
  shadowOffset: {
    width: 0,
    height: 0.6 * elevation * (invert ? 0 : 1)
  }
})
