import * as firebase from "firebase/app"

import { firebaseConfig } from "../../config"

try {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }
} catch (e) {
  console.log(e)
}

export default firebase
