import React, { Component } from "react"
import { Animated, Dimensions, StyleSheet, View, ViewStyle } from "react-native"

import { COLORS, getGradientColor } from "../utils/colors"
import Gradient from "./Gradient"

type Props = {
  progress: number
  animatedProgress?: Object
  color: string
  style?: ViewStyle
  barStyle?: ViewStyle
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "stretch",
    height: 2
  },
  pad: {
    width: 8
  },
  bar: {
    flex: 1
  }
})

class ColorBar extends Component<Props> {
  static defaultProps = {
    progress: 0,
    color: COLORS.grey
  }

  _progress = new Animated.Value(this.props.progress)
  _widthValue = new Animated.Value(Dimensions.get("window").width)

  componentDidMount() {
    this._animateProgress(this.props.progress)
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.progress !== this.props.progress)
      this._animateProgress(nextProps.progress)
  }

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.color !== this.props.color
  }

  _animateProgress(progress: number) {
    Animated.spring(this._progress, { toValue: progress }).start()
  }

  _updateWidth = ({ nativeEvent: { layout } }) =>
    this._widthValue.setValue(layout.width)

  render() {
    const {
      color,
      style,
      barStyle,
      progress,
      animatedProgress,
      ...other
    } = this.props
    const scaleProgress = animatedProgress || this._progress
    const gradColor = getGradientColor(color)
    const translate = {
      transform: [
        {
          translateX: Animated.multiply(
            Animated.multiply(
              -0.5,
              Animated.add(1, Animated.multiply(-1, scaleProgress))
            ),
            this._widthValue
          )
        }
      ]
    }
    const scale = { transform: [{ scaleX: scaleProgress }] }
    return (
      <View {...other} style={[styles.root, style]}>
        <View style={[styles.pad, { backgroundColor: color }]} />
        <Animated.View style={[styles.bar, translate]}>
          <Animated.View
            style={[styles.bar, scale, barStyle]}
            onLayout={this._updateWidth}
          >
            <Gradient
              style={styles.bar}
              colors={[color, gradColor]}
              start={[0, 0]}
              end={[1, 0]}
            />
          </Animated.View>
        </Animated.View>
      </View>
    )
  }
}

export default ColorBar
