import { isLocalhost } from "./utils/localhost"

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
}

export const isProduction = process.env.NODE_ENV === "production"

export const firebaseFunctionsBase = isLocalhost
  ? `http://localhost:3010/${firebaseConfig.projectId}/us-central1`
  : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`

export const gaTrackingID =
  process.env.REACT_APP_GA_TRACKING_ID || "UA-105611120-1"

export const sentryID = process.env.REACT_APP_SENTRY_DSN_JS

export const commitSha1 = process.env.REACT_APP_COMMIT_SHA1
  ? process.env.REACT_APP_COMMIT_SHA1.substring(0, 8)
  : "UNKNOWN"

export const uid = isProduction ? "" : process.env.REACT_APP_UID
