import React, { SFC } from "react"
import { StyleSheet, Text, TextStyle } from "react-native"

import { COLORS } from "../utils/colors"
import { webOnly } from "../utils/style"

const styles = StyleSheet.create({
  text: {
    backgroundColor: "transparent",
    fontFamily: "Roboto Condensed Light",
    fontSize: 18,
    lineHeight: 24,
    color: COLORS.white,
    ...webOnly({
      WebkitFontSmoothing: "antialiased"
    })
  }
})

type Props = {
  style?: TextStyle
}

const HeaderText: SFC<Props> = ({ style, children, ...other }) => {
  return <Text style={[styles.text, style]}>{children}</Text>
}

export default HeaderText
