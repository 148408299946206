import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { AuthStatus } from "../../services/auth/consts"

export const AUTHENTICATE = "auth__AUTHENTICATE"
export const AUTHENTICATE_SUCCESS = "auth__AUTHENTICATE_SUCCESS"
export const AUTHENTICATE_FAIL = "auth__AUTHENTICATE_FAIL"
export const LOG_IN = "auth__LOG_IN"
export const LOG_IN_SUCCESS = "auth__LOG_IN_SUCCESS"
export const LOG_IN_FAIL = "auth__LOG_IN_FAIL"
export const LOG_OUT = "auth__LOG_OUT"
export const IS_AUTHENTICATED_SET = "auth.isAuthenticated__SET"
export const STATUS_SET = "auth.status__SET"

const authenticate = () => createAction(AUTHENTICATE)

const authenticateSuccess = (token: string) =>
  createAction(AUTHENTICATE_SUCCESS, token)
const authenticateFail = () => createAction(AUTHENTICATE_FAIL)

const login = (customToken: string) => createAction(LOG_IN, customToken)
const loginSuccess = () => createAction(LOG_IN_SUCCESS)
const loginFail = () => createAction(LOG_IN_FAIL)
const logout = () => createAction(LOG_OUT)

const setIsAuthenticated = (isAuthenticated: boolean) =>
  createAction(IS_AUTHENTICATED_SET, isAuthenticated)
const setStatus = (status: AuthStatus) => createAction(STATUS_SET, status)

export const Actions = {
  authenticate,
  authenticateSuccess,
  authenticateFail,
  login,
  loginSuccess,
  loginFail,
  logout,
  setIsAuthenticated,
  setStatus
}

export type Actions = ActionsUnion<typeof Actions>
