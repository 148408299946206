import { routerMiddleware } from "connected-react-router"
import createBrowserHistory from "history/createBrowserHistory"
import createMemoryHistory from "history/createMemoryHistory"
import createRavenMiddleware from "raven-for-redux"
import Raven from "raven-js"
import { applyMiddleware, compose, createStore } from "redux"
import { createEpicMiddleware } from "redux-observable"
import { createMigrate, persistReducer, persistStore } from "redux-persist"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

import { isProduction } from "../config"
import * as firebaseApi from "../services/firebase/endpoints"
import { storage } from "../services/storage"
import epics from "./epics"
import { migrations, version } from "./migrations"
import createRootReducer from "./reducers"

// Configuration for redux-persist
const persistDebug = !isProduction && process.env.NODE_ENV !== "test"
const rootPersistConfig = {
  key: "root",
  version,
  storage,
  stateReconciler: autoMergeLevel2,
  debug: persistDebug,
  migrate: createMigrate(migrations, { debug: persistDebug }),
  blacklist: ["router", "alerts", "navigation"]
}

export default () => {
  // use memoryHistory when we don't have a DOM (tests, Native)
  const history =
    typeof document !== "undefined"
      ? createBrowserHistory()
      : createMemoryHistory()

  const composeEnhancers =
    //@ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const epicMiddleware = createEpicMiddleware({
    dependencies: { firebaseApi }
  })

  const middleware = composeEnhancers(
    applyMiddleware(
      epicMiddleware,
      routerMiddleware(history),
      createRavenMiddleware(Raven)
    )
  )

  const store = createStore(
    persistReducer(rootPersistConfig, createRootReducer(history)),
    middleware
  )
  const persistor = persistStore(store)

  epicMiddleware.run(epics)

  return { store, persistor, history }
}
