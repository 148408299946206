import React, { Component } from "react"
import { StyleSheet, View } from "react-native"
import { connect } from "react-redux"

import {
  ENTRY_UPDATE_TYPE_DRAG,
  ENTRY_UPDATE_TYPE_INPUT,
  ENTRY_UPDATE_TYPE_NONE,
  FLOATING_BUTTON_TYPE_CONFIRM
} from "../../consts"
import { Entry } from "../../schemas/entries"
import {
  selectMaxRemainingHoursForDate,
  selectRemainingHoursForDate
} from "../../store/days/selectors"
import {
  setSelectedEntry,
  setSelectedEntryHours,
  updateEntryHours
} from "../../store/entries/actions"
import { selectActiveEntryById } from "../../store/entries/selectors"
import { selectProjectColorById } from "../../store/projects/selectors"
import { selectTaskById } from "../../store/tasks/selectors"
import { Dispatch } from "../../store/types"
import { showDragHint } from "../../store/ui/actions"
import {
  setEntryUpdateType,
  setFloatingButtonType
} from "../../store/ui/actions"
import { selectEntryUpdateType } from "../../store/ui/selectors"
import { COLORS } from "../../utils/colors"
import TaskLabel from "./TaskLabel"
import TaskSlider from "./TaskSlider"

const styles = StyleSheet.create({
  root: {
    height: 56,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: COLORS.lightGrey,
    backfaceVisibility: "hidden",
    backgroundColor: "white"
  },
  isEditing: {
    zIndex: 10
  }
})

type Props = {
  name: string
  color: string
  entry: Entry
  updateType: string
  onUpdateInputStop: () => void
  onUpdateInputStart: (Entry) => void
  onUpdateDragStop: () => void
  onUpdate: (number) => void
  onUpdateDragStart: (Entry) => void
  remainingHours: number
  maxRemainingHours: number
  registerTapHandler: () => void
}

type State = {
  isActive: boolean
}

class Task extends Component<Props, State> {
  state = { isActive: false }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.updateType !== this.props.updateType) {
      this.setState({
        isActive: nextProps.updateType !== ENTRY_UPDATE_TYPE_NONE
      })
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.entry !== this.props.entry ||
      nextProps.entry.hours !== this.props.entry.hours ||
      nextProps.updateType === ENTRY_UPDATE_TYPE_NONE
    )
  }

  render() {
    const { entry, name }: Props = this.props

    return (
      <View style={[styles.root, this.state.isActive && styles.isEditing]}>
        <TaskSlider {...this.props} />
        <TaskLabel taskName={name} hours={entry.hours} />
      </View>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return function(state, ownProps) {
    const { entryId } = ownProps

    const entry = selectActiveEntryById(state, entryId)
    const task = selectTaskById(state, entry.taskId)
    const name = task.name
    const color = selectProjectColorById(state, task.projectId)
    const updateType = selectEntryUpdateType(state)
    const remainingHours = selectRemainingHoursForDate(state)
    const maxRemainingHours = selectMaxRemainingHoursForDate(state)

    return {
      name,
      entry,
      updateType,
      color,
      remainingHours,
      maxRemainingHours
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onUpdate: (hours: number) => dispatch(setSelectedEntryHours(hours)),
    onUpdateDragStart: (entry: Entry) => {
      dispatch(setSelectedEntry(entry))
      dispatch(setEntryUpdateType(ENTRY_UPDATE_TYPE_DRAG))
    },
    onUpdateDragStop: () => {
      dispatch(setEntryUpdateType(ENTRY_UPDATE_TYPE_NONE))
      dispatch(updateEntryHours())
    },
    onUpdateInputStart: (entry: Entry) => {
      dispatch(setSelectedEntry(entry))
      dispatch(setEntryUpdateType(ENTRY_UPDATE_TYPE_INPUT))
      dispatch(setFloatingButtonType(FLOATING_BUTTON_TYPE_CONFIRM))
      dispatch(showDragHint())
    },
    onUpdateInputStop: () => {
      dispatch(setEntryUpdateType(ENTRY_UPDATE_TYPE_NONE))
      dispatch(updateEntryHours())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Task)
