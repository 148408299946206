import { ofType } from "@martin_hotell/rex-tils"
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router"
import { ActionsObservable, StateObservable } from "redux-observable"
import { Observable, from, merge, of } from "rxjs"
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  share,
  switchMap,
  take,
  tap
} from "rxjs/operators"

import AuthService from "../../services/auth"
import { AuthStatus } from "../../services/auth/consts"
import { genericProvider } from "../../services/auth/providers/generic"
import { auth } from "../../services/firebase/auth"
import { COLORS } from "../../utils/colors"
import * as alertsActions from "../alerts/actions"
import * as daysActions from "../days/actions"
import * as entriesActions from "../entries/actions"
import * as messagingActions from "../messaging/actions"
import * as projectsActions from "../projects/actions"
import { State } from "../reducers"
import * as settingsActions from "../settings/actions"
import * as sourcesActions from "../sources/actions"
import * as tasksActions from "../tasks/actions"
import { trackError } from "../tracking/actions"
import * as userActions from "../user/actions"
import * as authActions from "./actions"
import { selecStatus } from "./selectors"

/**
 * Authenthicate with selected Provider
 */
export const authenticate = (action$: ActionsObservable<authActions.Actions>) =>
  action$.pipe(
    ofType(authActions.AUTHENTICATE),
    concatMap(() => [
      authActions.Actions.setIsAuthenticated(false),
      authActions.Actions.setStatus(AuthStatus.AUTH_STATUS_PARTIAL)
    ]),
    tap(() => {
      AuthService.acquireTokenAsync(genericProvider)
    })
  )

/**
 * Login after successful authentication
 */
export const loginAfterAuthenticate = (
  action$: ActionsObservable<authActions.Actions>
) =>
  action$.pipe(
    ofType(authActions.AUTHENTICATE_SUCCESS),
    map(({ payload: token }) => {
      return authActions.Actions.login(token)
    })
  )

/**
 * Auth State
 */

const onAuthStateChanged$ = Observable.create(obs =>
  auth.onAuthStateChanged(
    user => obs.next(user),
    err => obs.error(err),
    () => obs.complete()
  )
).pipe(share())

const onAuthChangedSuccess$ = from(onAuthStateChanged$).pipe(
  distinctUntilChanged(),
  filter(user => !!user),
  tap(user => console.log(user)),
  mergeMap(() => [
    authActions.Actions.setIsAuthenticated(true),
    sourcesActions.Actions.checkIfFresh(),
    messagingActions.Actions.initializeMessaging(),
    settingsActions.Actions.initializeSettings()
  ])
)

const onAuthChangedFail$ = from(onAuthStateChanged$).pipe(
  distinctUntilChanged(),
  filter(user => !user),
  tap(user => console.log(user)),
  mergeMap(() => [
    authActions.Actions.setIsAuthenticated(false),
    authActions.Actions.setStatus(AuthStatus.AUTH_STATUS_NONE),
    sourcesActions.Actions.unsetSources(),
    entriesActions.unsetEntries(),
    daysActions.Actions.unsetDays(),
    projectsActions.unsetProjects(),
    tasksActions.unsetTasks(),
    userActions.Actions.unsetUser()
  ])
)

export const authState = () => merge(onAuthChangedSuccess$, onAuthChangedFail$)

/**
 * Login
 */

const loginWithCustomToken = (customToken: string) =>
  from(auth.signInWithCustomToken(customToken))
const loginWithCustomTokenSuccess = (customToken: string) =>
  loginWithCustomToken(customToken).pipe(
    filter(user => !!user),
    mapTo(authActions.Actions.loginSuccess())
  )
const loginWithCustomTokenFail = (customToken: string) =>
  loginWithCustomToken(customToken).pipe(
    filter(user => !user),
    mapTo(authActions.Actions.loginFail())
  )

export const login = (action$: ActionsObservable<authActions.Actions>) =>
  action$.pipe(
    ofType(authActions.LOG_IN),
    mergeMap(({ payload: customToken }) =>
      merge(
        loginWithCustomTokenSuccess(customToken),
        loginWithCustomTokenFail(customToken)
      )
    ),
    catchError(error => [
      trackError(error),
      alertsActions.Actions.showSnackbar({
        message: error.message,
        color: COLORS.red
      })
    ])
  )

/**
 * Logout
 */
export const logout = (action$: ActionsObservable<authActions.Actions>) =>
  action$.pipe(
    ofType(authActions.LOG_OUT),
    concatMap(() => [
      from(AuthService.revokeTokenAsync(genericProvider, auth.currentUser.uid)),
      from(auth.signOut())
    ]),

    ignoreElements()
  )

/**
 * Complete Partial Login
 */
export const completePartialLogin = (
  action$: ActionsObservable<LocationChangeAction>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    take(1),
    filter(() => selecStatus(state$.value) === AuthStatus.AUTH_STATUS_PARTIAL),
    switchMap(action => {
      return AuthService.completePartialSignIn(
        genericProvider,
        action.payload.location.search
      )
    }),
    switchMap(({ status, token }) => {
      if (status === AuthStatus.AUTH_STATUS_COMPLETE) {
        return [
          authActions.Actions.setStatus(status),
          authActions.Actions.authenticateSuccess(token)
        ]
      } else if (status === AuthStatus.AUTH_STATUS_ERROR) {
        return of(authActions.Actions.setStatus(status))
      } else {
        return of(authActions.Actions.setStatus(status))
      }
    })
  )
