import React, { PureComponent } from "react"
import { StyleSheet, View } from "react-native"

import FullPanel from "../components/FullPanel"
import BottomBarContainer from "../containers/BottomBarContainer"
import CalendarContainer from "../containers/CalendarContainer"
import FloatingActionButtonSwitchContainer from "../containers/FloatingActionButtonSwitchContainer"
import HoursMinutesInputContainer from "../containers/HoursMinutesInputContainer"
import MenuBottomSheetContainer from "../containers/MenuBottomSheetContainer"
import MoreBottomSheetContainer from "../containers/MoreBottomSheetContainer"
import TaskListContainer from "../containers/TaskListContainer"
import TaskListHeaderContainer from "../containers/TaskListHeaderContainer"

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
})

type Props = { onMount: () => any }

export default class DayScreen extends PureComponent<Props> {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    return (
      <FullPanel>
        <FullPanel>
          <TaskListHeaderContainer />
          <View style={styles.container}>
            <TaskListContainer />
            <BottomBarContainer />
            <HoursMinutesInputContainer />
          </View>
        </FullPanel>
        <CalendarContainer />
        <FloatingActionButtonSwitchContainer />
        <MenuBottomSheetContainer />
        <MoreBottomSheetContainer />
      </FullPanel>
    )
  }
}
