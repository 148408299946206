/*

  hours and minutes text treatment
*/

import React from "react"
import { StyleSheet, Text, View } from "react-native"

import { COLORS } from "../utils/colors"
import { getHoursDisplay, getMinutesDisplay } from "../utils/days"
import { webOnly } from "../utils/style"

const styles = StyleSheet.create({
  root: {
    flexDirection: "row"
  },
  text: {
    fontFamily: "Roboto Condensed Light",
    fontSize: 24,
    lineHeight: 24,
    color: COLORS.text,
    ...webOnly({
      WebkitFontSmoothing: "antialiased"
    })
  },
  textSmall: {
    fontSize: 12,
    lineHeight: 12
  }
})

type Props = {
  hours: number
  style?: Object
  color?: string | boolean
  size?: number
}

export default function HoursMinutes(props: Props) {
  const { style, color, size, hours, ...other } = props
  const textStyle = [
    styles.text,
    { color: hours === 0 ? COLORS.white : COLORS.text },
    size && { fontSize: size, lineHeight: size }
  ]
  const smallSize = size ? Math.round(0.5 * size) : 12
  const smallStyle = [
    styles.textSmall,
    size && { fontSize: smallSize, lineHeight: smallSize }
  ]
  return (
    <View {...other} style={[styles.root, style]}>
      <Text style={textStyle}>{getHoursDisplay(hours)}</Text>
      <Text style={[textStyle, smallStyle]}>{getMinutesDisplay(hours)}</Text>
    </View>
  )
}
