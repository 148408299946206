import { RouterState, connectRouter } from "connected-react-router"
import { History } from "history"
import { combineReducers } from "redux"

import alerts, { State as AlertState } from "./alerts/reducer"
import auth, { State as AuthState } from "./auth/reducer"
import days, { State as DaysState } from "./days/reducer"
import entries, { State as EntriesState } from "./entries/reducer"
import messaging, { State as MessagingState } from "./messaging/reducer"
import navigation, { State as NavigationStage } from "./navigation/reducer"
import projects, { State as ProjectsState } from "./projects/reducer"
import settings, { State as SettingsState } from "./settings/reducer"
import sources, { State as SourcesState } from "./sources/reducer"
import tasks, { State as TasksState } from "./tasks/reducer"
import ui, { State as UiState } from "./ui/reducer"
import user, { State as UserState } from "./user/reducer"

const rootReducer = (history: History) =>
  combineReducers<State>({
    alerts,
    days,
    entries,
    messaging,
    navigation,
    projects,
    settings,
    sources,
    tasks,
    user,
    auth,
    ui,
    router: connectRouter(history)
  })

export interface State {
  alerts: AlertState
  auth: AuthState
  days: DaysState
  entries: EntriesState
  navigation: NavigationStage
  projects: ProjectsState
  tasks: TasksState
  user: UserState
  settings: SettingsState
  sources: SourcesState
  messaging: MessagingState
  router: RouterState
  ui: UiState
}

export default rootReducer
