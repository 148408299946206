import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

export const INITIALIZE_SETTINGS = "settings__INITIALIZE_SETTINGS"
export const FETCH_SETTINGS = "settings__FETCH_SETTINGS"
export const FETCH_SETTINGS_SUCCESS = "settings__FETCH_SETTINGS_SUCCESS"
export const FETCH_SETTINGS_FAIL = "settings__FETCH_SETTINGS_FAIL"
export const UPDATE_SETTINGS = "settings__UPDATE_SETTINGS"
export const SETTINGS_SET = "settings__SET"

export const initializeSettings = () => createAction(INITIALIZE_SETTINGS)
export const fetchSettings = () => createAction(FETCH_SETTINGS)
export const fetchSettingsSuccess = (settings: Object) =>
  createAction(FETCH_SETTINGS_SUCCESS, settings)
export const fetchSettingsFail = () => createAction(FETCH_SETTINGS_FAIL)
export const updateSettings = (settings: Object) =>
  createAction(UPDATE_SETTINGS, settings)
export const setSettings = (settings: Object) =>
  createAction(SETTINGS_SET, settings)

export const Actions = {
  initializeSettings,
  fetchSettings,
  fetchSettingsSuccess,
  fetchSettingsFail,
  updateSettings,
  setSettings
}

export type Actions = ActionsUnion<typeof Actions>
