import React, { Component, ReactNode } from "react"
import { Animated, StyleSheet, ViewStyle } from "react-native"

import { easeInOut, easeOut } from "../utils/easing"

type Props = {
  children: ReactNode
  contentStyle?: ViewStyle
  in: boolean
  style?: ViewStyle
  timing?: { enter: number; exit: number }
}

type State = {
  show: boolean
}

const ENTER = 300
const EXIT = 200
const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject
  }
})

class FadeIn extends Component<Props, State> {
  state = { show: this.props.in }

  _progress = new Animated.Value(this.props.in ? 1 : 0)

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.in === this.props.in) return
    if (nextProps.in) this._animateIn()
    else this._animateOut()
  }

  _animateIn() {
    this.setState({ show: true })
    Animated.timing(this._progress, {
      toValue: 1,
      duration: ENTER,
      easing: easeOut
    }).start()
  }

  _animateOut() {
    Animated.timing(this._progress, {
      toValue: 0,
      duration: EXIT,
      easing: easeInOut
    }).start(() => this.setState({ show: false }))
  }

  render() {
    if (!this.state.show) return null
    return (
      <Animated.View
        style={[
          styles.root,
          { opacity: this._progress },
          this.props.contentStyle
        ]}
      >
        {this.props.children}
      </Animated.View>
    )
  }
}

export default FadeIn
