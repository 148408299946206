import React, { PureComponent } from "react"
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native"

import { COLORS } from "../../utils/colors"
import BoldText from "../BoldText"
import Icon from "../Icon"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  icon: {
    marginRight: 16
  }
})

type Props = {
  text: string
  icon: string
  onPress: () => void
}

export default class BottomSheetButton extends PureComponent<Props> {
  _handlePress = () => {
    this.props.onPress()
  }

  render() {
    const { text, icon }: Props = this.props

    return (
      <TouchableWithoutFeedback onPress={this._handlePress}>
        <View style={styles.container}>
          {!!icon && (
            <Icon
              size={24}
              color={COLORS.text}
              icon={icon}
              style={styles.icon}
            />
          )}
          <BoldText>{text}</BoldText>
        </View>
      </TouchableWithoutFeedback>
    )
  }
}
