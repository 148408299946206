import * as sourcesActions from "./actions"

export const initialState = {
  dataFrom: undefined,
  dataTo: undefined,
  isFetching: false,
  lastFetched: undefined
}

export type State = typeof initialState

export const reducer = (
  state: State = initialState,
  action: sourcesActions.Actions
): State => {
  switch (action.type) {
    case sourcesActions.IS_FETCHING_SET: {
      const { payload: isFetching } = action

      return {
        ...state,
        isFetching
      }
    }
    case sourcesActions.LAST_FETCHED_SET: {
      const { payload: lastFetched } = action

      return {
        ...state,
        lastFetched
      }
    }
    case sourcesActions.DATA_FROM_SET: {
      const { payload: dataFrom } = action

      return {
        ...state,
        dataFrom
      }
    }
    case sourcesActions.DATA_TO_SET: {
      const { payload: dataTo } = action

      return {
        ...state,
        dataTo
      }
    }

    case sourcesActions.UNSET: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
