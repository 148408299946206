import * as React from "react"
import { StyleSheet, View } from "react-native"

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingHorizontal: 20
  }
})

type Props = { children?: React.ReactNode }

export default function CalendarLabels({ children }: Props) {
  return <View style={styles.container}>{children}</View>
}
