import padStart from "lodash/padStart"
import moment from "moment"

// get today as timestamp, regardless of timezone
// convert local time to string and create UTC date, then timestamp
export const getToday = (): number => +moment.utc(moment().format("YYYY-MM-DD"))

/**
 * Get day name from timestamp
 * @param timestamp
 */
export const getDayName = (timestamp: number): string =>
  moment.utc(timestamp).format("dddd")

/**
 * Get month name from timestamp
 * @param timestamp
 */
export const getMonthName = (timestamp: number): string =>
  moment.utc(timestamp).format("MMMM")

export const getTitle = (timestamp: number): string => {
  // use local time
  const today = getToday()
  const yesterday = +moment.utc(today).subtract(1, "day")
  if (timestamp === today) return "Today"

  if (timestamp === yesterday) return "Yesterday"

  return getDayName(timestamp)
}

export const getDayMonthDate = (timestamp: number): string =>
  `${getTitle(timestamp)}, ${getMonthName(timestamp)} ${moment
    .utc(timestamp)
    .date()}`

export const getHoursDisplay = (hours: number): string => {
  // remove the fraction and pad with zeros
  return padStart(String(Math.floor(hours)), 2, "0")
}

export const getMinutesDisplay = (hours: number): string => {
  // get fraction part of day & convert to minutes
  const minutes = Math.round((hours % 1) * 60)
  // pad with zeros
  return padStart(String(Math.floor(minutes)), 2, "0")
}

/**
 * Get next day
 * @param timestamp
 */
export const getNextDate = (timestamp: number): number =>
  +moment.utc(timestamp).add(1, "day")

/**
 *
 * @param timestamp Get prev day
 */
export const getPrevDate = (timestamp: number): number =>
  +moment.utc(timestamp).subtract(1, "day")

export const getDaysDiffFromNow = (timestamp: number): number =>
  moment.utc(getToday()).diff(moment.utc(timestamp), "days")

// use local time for today
export const isToday = (timestamp: number) =>
  getToday() === +moment.utc(timestamp)
