import moment from "moment"
import React, { PureComponent } from "react"
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native"

import { COLORS } from "../../utils/colors"
import BodyText from "../BodyText"

const styles = StyleSheet.create({
  container: {
    flexBasis: 28,
    justifyContent: "center",
    alignItems: "center",
    height: 52
  },
  unselectableText: {
    color: COLORS.grey
  },
  todoUnderline: {
    position: "absolute",
    bottom: 10,
    width: 28,
    height: 5,
    backgroundColor: COLORS.red,
    borderRadius: 3
  },
  notworkingUnderline: {
    position: "absolute",
    bottom: 10,
    width: 28,
    height: 5,
    backgroundColor: COLORS.lightGrey,
    borderRadius: 3
  }
})

type Props = {
  onSelect: (timestamp: number) => void
  date: number
  todo: boolean
  isSelectable: boolean
  isWorkingDay: boolean
}

export default class CalendarDay extends PureComponent<Props> {
  _handlePress(): void {
    this.props.onSelect(this.props.date)
  }

  render() {
    const { date, todo, isSelectable, isWorkingDay } = this.props
    // if (isSelectable)
    return (
      <TouchableWithoutFeedback
        onPress={() => this._handlePress()}
        disabled={!isSelectable}
      >
        <View style={styles.container}>
          <BodyText style={!isSelectable ? styles.unselectableText : {}}>
            {moment.utc(date).date()}
          </BodyText>
          {!!todo && isSelectable && <View style={styles.todoUnderline} />}
          {!isWorkingDay && <View style={styles.notworkingUnderline} />}
        </View>
      </TouchableWithoutFeedback>
    )
  }
}
