import { goBack } from "connected-react-router"
import moment from "moment"
import React from "react"
import { StyleSheet } from "react-native"
import { connect } from "react-redux"

import { FLOATING_BUTTON_TYPE_CALENDAR } from "../../consts"
import { selectCurrentDate } from "../../store/navigation/selectors"
import { Dispatch } from "../../store/types"
import { setFloatingButtonType } from "../../store/ui/actions"
import BodyText from "../BodyText"
import FloatingActionButton from "./FloatingActionButton"

const styles = StyleSheet.create({
  month: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: 9,
    lineHeight: 12
  },
  date: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: 28,
    lineHeight: 28
  }
})

type Props = {
  month: string
  date: string
  onPress: () => void
}

function DayFloatingActionButton(props: Props) {
  return (
    <FloatingActionButton onPress={props.onPress}>
      <BodyText style={styles.month}>{props.month.toUpperCase()}</BodyText>
      <BodyText style={styles.date}>{props.date}</BodyText>
    </FloatingActionButton>
  )
}

const stateToProps = state => {
  const d = moment.utc(selectCurrentDate(state))
  return {
    month: d.format("MMM"),
    date: String(d.date())
  }
}

const dispatchToProps = (dispatch: Dispatch) => ({
  onPress: () => {
    dispatch(goBack())
    dispatch(setFloatingButtonType(FLOATING_BUTTON_TYPE_CALENDAR))
  }
})

export default connect(
  stateToProps,
  dispatchToProps
)(DayFloatingActionButton)
