import { State } from "../reducers"

/**
 * Returns type of entry updating operation (input / drag / none)
 */
export const selectEntryUpdateType = (state: State) => state.ui.entryUpdateType
export const selectFloatingButtonType = (state: State) =>
  state.ui.floatingButtonType

export const selectShowDragHint = (state: State) => state.ui.showDragHint
