// increment the version, and add the a function to the same-numbered key in
// the migrations object below
export const version = 0

// see https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md or
// https://medium.freecodecamp.org/how-to-use-redux-persist-when-migrating-your-states-a5dee16b5ead
// for example useage of this method.
export const migrations = {
  0: state => state // noop migration
}
