import React, { PureComponent } from "react"

import { EntriesFilterBy, EntriesSortBy } from "../../consts"
import LightText from "../LightText"
import BottomSheet from "./BottomSheet"
import BottomSheetButton from "./BottomSheetButton"
import BottomSheetDivider from "./BottomSheetDivider"
import BottomSheetItem from "./BottomSheetItem"
import BottomSheetRadioButton from "./BottomSheetRadioButton"

type Props = {
  show: boolean
  onFilterByChange: (filterBy: EntriesFilterBy) => void
  onSortByChange: (sortBy: EntriesSortBy) => void
  onOutsidePress: () => void
  onClearAllPress: () => void
  filterBy: string
  sortBy: string
}
type State = {
  sortBy: string
  filterBy: string
}

export default class MoreBottomSheet extends PureComponent<Props, State> {
  state = { sortBy: this.props.sortBy, filterBy: this.props.filterBy }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.sortBy !== this.state.sortBy) {
      this.setState({ sortBy: nextProps.sortBy })
    }

    if (nextProps.filterBy !== this.state.filterBy) {
      this.setState({ filterBy: nextProps.filterBy })
    }
  }

  _handleFilterByChange(filterBy: EntriesFilterBy) {
    this.props.onFilterByChange(filterBy)
  }

  _handleSortByChange(sortBy: EntriesSortBy) {
    this.props.onSortByChange(sortBy)
  }

  _handleClearAllPress() {
    this.props.onClearAllPress()
  }

  render() {
    return (
      <BottomSheet
        in={this.props.show}
        onOutsidePress={this.props.onOutsidePress}
      >
        <BottomSheetItem>
          <LightText>Sort by:</LightText>
        </BottomSheetItem>
        <BottomSheetItem>
          <BottomSheetRadioButton
            text="Project name"
            selected={this.state.sortBy === EntriesSortBy.NAME}
            onPress={() => this._handleSortByChange(EntriesSortBy.NAME)}
          />
        </BottomSheetItem>
        <BottomSheetItem>
          <BottomSheetRadioButton
            text="Most recent"
            selected={this.state.sortBy === EntriesSortBy.RECENT}
            onPress={() => this._handleSortByChange(EntriesSortBy.RECENT)}
          />
        </BottomSheetItem>
        <BottomSheetDivider />
        <BottomSheetItem>
          <LightText>Show:</LightText>
        </BottomSheetItem>
        <BottomSheetItem>
          <BottomSheetRadioButton
            text="All tasks"
            selected={this.state.filterBy === EntriesFilterBy.SHOW_ALL}
            onPress={() => this._handleFilterByChange(EntriesFilterBy.SHOW_ALL)}
          />
        </BottomSheetItem>
        <BottomSheetItem>
          <BottomSheetRadioButton
            text="With hours"
            selected={this.state.filterBy === EntriesFilterBy.SHOW_WITH_HOURS}
            onPress={() =>
              this._handleFilterByChange(EntriesFilterBy.SHOW_WITH_HOURS)
            }
          />
        </BottomSheetItem>
        <BottomSheetDivider />
        <BottomSheetItem>
          <BottomSheetButton
            text="Clear all entries"
            icon="clear"
            onPress={() => this._handleClearAllPress()}
          />
        </BottomSheetItem>
      </BottomSheet>
    )
  }
}
