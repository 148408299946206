import { AlertPayload, SnackbarPayload } from "../../schemas/alerts"
import * as alertsActions from "./actions"

export const initialState = {
  alert: null as AlertPayload,
  snackbar: null as SnackbarPayload
}
export type State = typeof initialState

const reducer = (
  state: State = initialState,
  action: alertsActions.Actions
): State => {
  switch (action.type) {
    case alertsActions.ALERTS_ALERT_SET: {
      const { payload: alert } = action

      return {
        ...state,
        alert
      }
    }

    case alertsActions.ALERTS_ALERT_UNSET: {
      return {
        ...state,
        alert: null
      }
    }

    case alertsActions.ALERTS_SNACKBAR_SET: {
      const { payload: snackbar } = action

      return {
        ...state,
        snackbar
      }
    }

    case alertsActions.ALERTS_SNACKBAR_UNSET: {
      return {
        ...state,
        snackbar: null
      }
    }

    default:
      return state
  }
}

export default reducer
