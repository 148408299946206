import { goBack } from "connected-react-router"
import { connect } from "react-redux"

import MenuBottomSheet from "../components/BottomSheet/MenuBottomSheet"
import { FEEDBACK_FORM_URL, ROUTER_ROUTE_MENU } from "../consts"
import { matchPath, withRouter } from "../services/router"
import { Actions as AuthActions } from "../store/auth/actions"
import { Dispatch } from "../store/types"
import { selectUserName } from "../store/user/selectors"

const stateToProps = (state, ownProps) => ({
  userName: selectUserName(state),
  show: !!matchPath(ownProps.location.pathname, { path: ROUTER_ROUTE_MENU })
})

const dispatchToProps = (dispatch: Dispatch) => ({
  onOutsidePress: () => dispatch(goBack()),
  onFeedbackPress: () => window.open(FEEDBACK_FORM_URL),
  onLogoutPress: () => dispatch(AuthActions.logout())
})

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps
  )(MenuBottomSheet)
)
