import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { State } from "./reducer"

export const DAYS_CREATE = "days__CREATE"
export const DAYS_SET = "days__SET"
export const DAYS_UNSET = "days__UNSET"

/**
 * createDays
 */
const createDays = () => createAction(DAYS_CREATE)
/**
 * setDays
 */
const setDays = (payload: State) => createAction(DAYS_SET, payload)
/**
 * unsetDays
 */
const unsetDays = () => createAction(DAYS_UNSET)

export const Actions = {
  createDays,
  setDays,
  unsetDays
}

export type Actions = ActionsUnion<typeof Actions>
