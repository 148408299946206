import React from "react"
import { StyleSheet, View, ViewStyle } from "react-native"

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    width: "100%"
  }
})

interface IProps {
  style?: ViewStyle
  children: JSX.Element
}

export default function BottomSheetItem(props: IProps) {
  return <View style={[styles.container, props.style]}>{props.children}</View>
}
