import React, { PureComponent } from "react"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper"
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"

import FullPanel from "./components/FullPanel"
import {
  ROUTER_ROUTE_CALENDAR,
  ROUTER_ROUTE_DAY,
  ROUTER_ROUTE_HOME,
  ROUTER_ROUTE_LOGIN,
  ROUTER_ROUTE_MENU,
  ROUTER_ROUTE_MORE
} from "./consts"
import AlertContainer from "./containers/AlertContainer"
import DayScreenContainer from "./containers/DayScreenContainer"
import LoadingContainer from "./containers/LoadingContainer"
import LoginScreenContainer from "./containers/LoginScreenContainer"
import SnackbarContainer from "./containers/SnackbarContainer"
import { Route, withRouter } from "./services/router"
import { selectIsAuthenticated } from "./store/auth/selectors"
import { State } from "./store/reducers"

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: ROUTER_ROUTE_LOGIN,
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => selectIsAuthenticated(state),
  // A nice display name for this check
  wrapperDisplayName: "UserIsAuthenticated"
})

const locationHelper = locationHelperBuilder({})
const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state: State, ownProps: any) => ROUTER_ROUTE_HOME,
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // This prevents us from adding the query parameter when we send the user away from the login page
  // Determine if the user is authenticated or not
  authenticatedSelector: (state: State) => !selectIsAuthenticated(state),
  // A nice display name for this check
  wrapperDisplayName: "UserIsNotAuthenticated"
})

const ProtectedDayScreen = userIsAuthenticated(DayScreenContainer)
const RedirectLogin = userIsNotAuthenticated(LoginScreenContainer)

class App extends PureComponent<{}> {
  render() {
    return (
      <FullPanel>
        <Route
          path={`(${ROUTER_ROUTE_HOME}|${ROUTER_ROUTE_DAY}|${ROUTER_ROUTE_CALENDAR}|${ROUTER_ROUTE_MENU}|${ROUTER_ROUTE_MORE})`}
          component={ProtectedDayScreen}
        />
        <Route path={ROUTER_ROUTE_LOGIN} component={RedirectLogin} />

        <LoadingContainer />
        <SnackbarContainer />
        <AlertContainer />
      </FullPanel>
    )
  }
}

export default withRouter(App)
