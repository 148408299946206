import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

export const INITIALIZE_MESSAGING = "messaging__INITIALIZE_MESSAGING"
export const REQUEST_PERMISSION = "messaging__REQUEST_PERMISSION"
export const REGISTER_MESSAGING_TOKEN = "messaging__REGISTER_MESSAGING_TOKEN"
export const UNREGISTER_MESSAGING_TOKEN =
  "messaging__UNREGISTER_MESSAGING_TOKEN"
export const MESSAGING_TOKEN_SET = "messaging.token__SET"
export const MESSAGING_TOKEN_UNSET = "messaging.token__UNSET"
export const MESSAGING_TOKEN_SAVED_SET = "messaging.tokenSaved__SET"
export const SUBSCRIBE_TO_TOPIC = "messaging__SUBSCRIBE_TO_TOPIC"
export const UNSUBSCRIBE_FROM_TOPIC = "messaging__UNSUBSCRIBE_FROM_TOPIC"
export const TOPIC_ADD = "messaging.topics__ADD"
export const TOPIC_REMOVE = "messaging.topics__REMOVE"
export const MESSAGING_TOPICS_INITIALIZED_SET =
  "messaging.topicsInitialized__SET"
export const INITIALIZE_TOPICS = "messaging__INITIALIZE_TOPICS"

const initializeMessaging = () => createAction(INITIALIZE_MESSAGING)
const requestPermission = () => createAction(REQUEST_PERMISSION)
const registerMessagingToken = (token: string) =>
  createAction(REGISTER_MESSAGING_TOKEN, token)
const unregisterMessagingToken = (token: string) =>
  createAction(UNREGISTER_MESSAGING_TOKEN, token)
const setMessagingToken = (token: string) =>
  createAction(MESSAGING_TOKEN_SET, token)

const unsetMessagingToken = () => createAction(MESSAGING_TOKEN_UNSET)

const setMessagingTokenSaved = (tokenSaved: boolean) =>
  createAction(MESSAGING_TOKEN_SAVED_SET, tokenSaved)

const subscribeToTopic = (topicName: string) =>
  createAction(SUBSCRIBE_TO_TOPIC, topicName)

const unsubscribeFromTopic = (topicName: string) =>
  createAction(UNSUBSCRIBE_FROM_TOPIC, topicName)

const addTopic = (topicName: string) => createAction(TOPIC_ADD, topicName)

const removeTopic = (topicName: string) => createAction(TOPIC_REMOVE, topicName)

const setMessagingTopicsInitialized = (topicsInitialized: boolean) =>
  createAction(MESSAGING_TOPICS_INITIALIZED_SET, topicsInitialized)

const initializeTopics = () => createAction(INITIALIZE_TOPICS)

export const Actions = {
  initializeMessaging,
  requestPermission,
  registerMessagingToken,
  unregisterMessagingToken,
  setMessagingToken,
  unsetMessagingToken,
  setMessagingTokenSaved,
  subscribeToTopic,
  unsubscribeFromTopic,
  addTopic,
  removeTopic,
  setMessagingTopicsInitialized,
  initializeTopics
}

export type Actions = ActionsUnion<typeof Actions>
