import React, { PureComponent } from "react"
import { StyleSheet, Text, TextStyle, View } from "react-native"

import { COLORS } from "../utils/colors"
import { getHoursDisplay, getMinutesDisplay } from "../utils/days"
import { webOnly } from "../utils/style"

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: 50
  },
  text: {
    backgroundColor: "transparent",
    fontFamily: "Roboto Condensed",
    fontSize: 50,
    lineHeight: 50,
    color: COLORS.white,
    ...webOnly({
      WebkitFontSmoothing: "antialiased"
    })
  }
})

type Props = {
  hours: number
  style?: TextStyle
}

class HoursMinutesLarge extends PureComponent<Props> {
  render() {
    const { hours, style } = this.props
    return (
      <View style={[styles.root]}>
        <Text style={[styles.text, style]}>{getHoursDisplay(hours)}</Text>
        <Text style={[styles.text, style]}>:</Text>
        <Text style={[styles.text, style]}>{getMinutesDisplay(hours)}</Text>
      </View>
    )
  }
}

export default HoursMinutesLarge
