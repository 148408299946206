import React, { PureComponent } from "react"
import { StyleSheet, ViewStyle } from "react-native"

import { getGradientColor } from "../utils/colors"
import { createShadowElevation } from "../utils/elevation"
import Gradient from "./Gradient"

const PRIMARY = "#36a7f8"
const SECONDARY = "#50dd8e"
const styles = StyleSheet.create({
  root: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    height: 85,
    paddingHorizontal: 20,
    zIndex: 4,
    ...createShadowElevation(4)
  }
})

type Props = {
  primary?: string
  secondary?: string
  style?: ViewStyle
}

class Header extends PureComponent<Props> {
  render() {
    const { secondary, style, ...other }: Props = this.props
    const primary = this.props.primary || PRIMARY
    const gradColor =
      secondary ||
      (this.props.primary && getGradientColor(primary)) ||
      SECONDARY
    const bg = { backgroundColor: primary }
    return (
      <Gradient
        {...other}
        colors={[primary, gradColor]}
        //@ts-ignore
        style={[styles.root, bg, style]}
        start={[0, 0]}
        end={[1, 0]}
      />
    )
  }
}

export default Header
