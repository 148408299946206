import React, { PureComponent } from "react"
import {
  Animated,
  StyleSheet,
  Text,
  TouchableWithoutFeedback
} from "react-native"

import { SnackbarPayload } from "../schemas/alerts"
import { createShadowElevation } from "../utils/elevation"
import { webOnly } from "../utils/style"
import Header from "./Header"

type Props = {
  hideSnackbar: () => any
  snackbar?: SnackbarPayload
}

type State = {
  snackbar?: SnackbarPayload
}

const AUTO_HIDE_DURATION = 3000
const styles = StyleSheet.create({
  root: {
    position: "absolute",
    left: 16,
    right: 16,
    bottom: 0
  },
  header: {
    height: "auto",
    padding: 16,
    ...createShadowElevation(6)
  },
  title: {
    backgroundColor: "transparent",
    fontFamily: "Roboto Condensed Light",
    fontSize: 18,
    color: "white",
    ...webOnly({
      WebkitFontSmoothing: "antialiased"
    })
  }
})

export default class Snackbar extends PureComponent<Props, State> {
  state = { snackbar: this.props.snackbar || { message: "" } }

  _progress = new Animated.Value(0)
  _layout: { x: number; y: number; width: number; height: number } = null
  _timeoutID: number

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.snackbar !== this.props.snackbar) {
      if (nextProps.snackbar) this._animateIn(nextProps)
      else this._animateOut()
    }
  }

  _animateIn({ snackbar, hideSnackbar }: Props) {
    const autoHideDuration =
      (snackbar && snackbar.autoHideDuration) || AUTO_HIDE_DURATION
    this.setState({ snackbar: snackbar })
    clearTimeout(this._timeoutID)
    Animated.spring(this._progress, { toValue: 1 }).start(() => {
      this._timeoutID = window.setTimeout(hideSnackbar, autoHideDuration)
    })
  }

  _animateOut() {
    Animated.spring(this._progress, { toValue: 0 }).start(() =>
      this.setState({ snackbar: null })
    )
  }

  _getRange = () => ({
    inputRange: [0, 1],
    outputRange: [(this._layout && this._layout.height) || 200, -100]
  })

  _onLayout = ({ nativeEvent: { layout } }) => (this._layout = layout)

  render() {
    if (!this.state.snackbar) {
      return null
    }

    const { hideSnackbar } = this.props
    const { message, color } = this.state.snackbar

    return (
      <Animated.View
        style={[
          styles.root,
          {
            transform: [
              { translateY: this._progress.interpolate(this._getRange()) }
            ]
          }
        ]}
        onLayout={this._onLayout}
      >
        <TouchableWithoutFeedback onPress={hideSnackbar}>
          <Header style={styles.header} primary={color}>
            <Text style={styles.title}>{message}</Text>
          </Header>
        </TouchableWithoutFeedback>
      </Animated.View>
    )
  }
}
