import * as messagingActions from "./actions"

export const initialState = {
  token: null,
  tokenSaved: false,
  topics: [],
  defaultTopics: ["daily", "weekly"],
  topicsInitialized: false
}

export type State = typeof initialState

export const messagingReducer = (
  state: State = initialState,
  action: messagingActions.Actions
): State => {
  switch (action.type) {
    case messagingActions.MESSAGING_TOKEN_SET: {
      const { payload: token } = action

      return {
        ...state,
        token
      }
    }

    case messagingActions.MESSAGING_TOKEN_UNSET: {
      return {
        ...state,
        token: null
      }
    }

    case messagingActions.MESSAGING_TOKEN_SAVED_SET: {
      const { payload: tokenSaved } = action

      return {
        ...state,
        tokenSaved
      }
    }

    case messagingActions.MESSAGING_TOPICS_INITIALIZED_SET: {
      const { payload: topicsInitialized } = action

      return {
        ...state,
        topicsInitialized
      }
    }

    case messagingActions.TOPIC_ADD: {
      const { payload: topicName } = action
      const topics = state.topics
      const newTopics = !topics.find(t => t === topicName)
        ? topics.concat(topicName)
        : topics

      return { ...state, topics: newTopics }
    }

    case messagingActions.TOPIC_REMOVE: {
      const { payload: topicName } = action
      const topics = state.topics
      const newTopics = topics.filter(t => t !== topicName)

      return { ...state, topics: newTopics }
    }

    default:
      return state
  }
}

export default messagingReducer
