import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { AlertPayload, SnackbarPayload } from "../../schemas/alerts"

export const ALERTS_ALERT_UNSET = "alerts.alert__UNSET"
export const ALERTS_ALERT_SET = "alerts.alert__SET"
export const ALERTS_SNACKBAR_UNSET = "alerts.snackbar__UNSET"
export const ALERTS_SNACKBAR_SET = "alerts.snackbar__SET"

/**
 * showAlert
 */
const showAlert = (alert: AlertPayload) => createAction(ALERTS_ALERT_SET, alert)

/**
 * hideAlert
 */
const hideAlert = () => createAction(ALERTS_ALERT_UNSET)
/**
 * showSnackbar
 */
const showSnackbar = (snackbar: SnackbarPayload) =>
  createAction(ALERTS_SNACKBAR_SET, snackbar)
/**
 * hideSnackbar
 */
const hideSnackbar = () => createAction(ALERTS_SNACKBAR_UNSET)

export const Actions = {
  showAlert,
  hideAlert,
  showSnackbar,
  hideSnackbar
}

export type Actions = ActionsUnion<typeof Actions>
