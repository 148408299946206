import React, { ReactNode } from "react"
import { StyleSheet, View } from "react-native"

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingHorizontal: 20
  }
})

type Props = { children: ReactNode }

export default function CalendarWeek(props: Props) {
  return <View style={styles.container}>{props.children}</View>
}
