import React, { PureComponent } from "react"
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native"

import { COLORS } from "../../utils/colors"
import BoldText from "../BoldText"
import Icon from "../Icon"
import LightText from "../LightText"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 4
  }
})

type Props = {
  text: string
  selected: boolean
  onPress: () => void
}

type State = {
  selected: boolean
  text: string
}

export default class BottomSheetRadioButton extends PureComponent<
  Props,
  State
> {
  state = { selected: this.props.selected || false, text: this.props.text }

  _handlePress = () => {
    this.props.onPress()
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.selected !== this.state.selected) {
      this.setState({ selected: nextProps.selected })
    }
  }

  render() {
    const { text, selected } = this.state

    return (
      <TouchableWithoutFeedback onPress={this._handlePress}>
        <View style={styles.container}>
          {selected && (
            <BoldText style={{ color: COLORS.text }}>{text}</BoldText>
          )}
          {!selected && (
            <LightText style={{ color: COLORS.lightText }}>{text}</LightText>
          )}
          <Icon
            size={24}
            color={selected ? COLORS.text : COLORS.lightText}
            icon={selected ? "radio_button_checked" : "radio_button_unchecked"}
          />
        </View>
      </TouchableWithoutFeedback>
    )
  }
}
