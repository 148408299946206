import React, { Component, ReactNode } from "react"
import { Animated, Dimensions, StyleSheet, View, ViewStyle } from "react-native"
import { TouchableWithoutFeedback } from "react-native"

import { easeInOut, easeOut } from "../../utils/easing"

const ENTER = 200
const EXIT = 200
const opacityRange = { inputRange: [0, 1], outputRange: [0, 0.32] }
const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject
  },
  mask: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#000000"
  },
  content: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    display: "flex",
    backgroundColor: "white",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingTop: 8,
    paddingBottom: 8,
    alignSelf: "stretch",
    justifyContent: "flex-end",
    alignItems: "stretch"
  }
})

type Props = {
  children: ReactNode
  contentStyle?: ViewStyle
  in: boolean
  style?: ViewStyle
  onOutsidePress: () => void
}

type State = {
  show: boolean
}

export default class BottomSheet extends Component<Props, State> {
  state = { show: this.props.in }

  progress = new Animated.Value(this.props.in ? 1 : 0)

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.in === this.props.in) return
    if (nextProps.in) this._animateIn()
    else this._animateOut()
  }

  private _animateIn() {
    this.setState({ show: true })
    Animated.timing(this.progress, {
      toValue: 1,
      duration: ENTER,
      easing: easeOut
    }).start()
  }

  private _animateOut() {
    Animated.timing(this.progress, {
      toValue: 0,
      duration: EXIT,
      easing: easeInOut
    }).start(() => this.setState({ show: false }))
  }

  private _handleOutsidePress = () => {
    this.props.onOutsidePress()
  }

  render() {
    const slideRange = {
      inputRange: [0, 1],
      outputRange: [Dimensions.get("window").height, 0]
    }
    const maskOpacity = {
      opacity: this.progress.interpolate(opacityRange)
    }
    const contentSlide = {
      transform: [{ translateY: this.progress.interpolate(slideRange) }]
    }
    return (
      <View
        style={[
          styles.root,
          this.props.style,
          !this.state.show && { display: "none" }
        ]}
      >
        <TouchableWithoutFeedback onPress={() => this._handleOutsidePress()}>
          <Animated.View style={[styles.mask, maskOpacity]} />
        </TouchableWithoutFeedback>
        <Animated.View style={[styles.content, contentSlide]}>
          {this.props.children}
        </Animated.View>
      </View>
    )
  }
}
