import { isStandalone } from "../../../utils/standalone"
import { AuthStatus } from "../consts"
import { AuthResult, IProvider } from ".."

function siginInWithPopup(
  requestKey: string,
  provider: IProvider
): Promise<AuthResult> {
  const [width, height] = [500, 500]
  const windowOptions = {
    width,
    height,

    left:
      Math.floor(window.screen.width / 2 - width / 2) +
      //@ts-ignore
      (window.screen.avaiLeft || 0),
    top: Math.floor(window.screen.height / 2 - height / 2)
  }

  const oauthAuthorizeUrl = provider.buildAuthorizeUrl()

  const windowOptionString = Object.entries(windowOptions)
    .map(([key, value]) => `${key}=${String(value)}`)
    .join(",")

  const loginWindow = window.open(
    oauthAuthorizeUrl,
    requestKey,
    windowOptionString
  )

  return new Promise((resolve, reject) => {
    // Poll for when the is closed
    const checkWindow = loginWindow => {
      // If window is still open check again later
      if (!loginWindow.closed) {
        setTimeout(() => checkWindow(loginWindow), 100)
        return
      }

      const redirectUrl = window.localStorage.getItem(requestKey)
      window.localStorage.removeItem(requestKey)

      parseUrl(redirectUrl, provider)
        .then(resolve)
        .catch(reject)
    }
    checkWindow(loginWindow)
  })
}

function signInWithRedirect(
  requestKey: string,
  provider: IProvider
): Promise<AuthResult> {
  return new Promise((resolve, reject) => {
    resolve({
      status: AuthStatus.AUTH_STATUS_PARTIAL,
      token: null
    })

    const oauthAuthorizeUrl = provider.buildAuthorizeUrl()

    window.location.assign(oauthAuthorizeUrl)
  })
}

export function completeRedirect(
  redirectUrl: string,
  provider: IProvider
): Promise<AuthResult> {
  return new Promise((resolve, reject) => {
    parseUrl(redirectUrl, provider)
      .then(resolve)
      .catch(reject)
  })
}

function parseUrl(
  redirectUrl: string,
  provider: IProvider
): Promise<AuthResult> {
  return new Promise((resolve, reject) => {
    // Window was closed, but never reached the redirect.html due to user closing window or network error during authentication
    if (typeof redirectUrl !== "string" || redirectUrl.length === 0) {
      reject({
        status: AuthStatus.AUTH_STATUS_ERROR,
        error: new Error(
          `Login window was closed by the user or authentication was incomplete and never reached final redirect page.`
        )
      })
    }

    // Window was closed, and reached the redirect.html; however there still might have been error during authentication, check url
    const error = provider.extractError(redirectUrl)
    if (error) {
      reject({ status: AuthStatus.AUTH_STATUS_ERROR, error: error })
    }

    // Window was closed, reached redirect.html and correctly added tokens to the url
    const token = provider.extractToken(redirectUrl)

    resolve({
      status: AuthStatus.AUTH_STATUS_COMPLETE,
      token
    })
  })
}

export function signIn(requestKey: string, provider: IProvider) {
  if (isStandalone) {
    return signInWithRedirect(requestKey, provider)
  } else {
    return siginInWithPopup(requestKey, provider)
  }
}
