import { goBack } from "connected-react-router"
import { connect } from "react-redux"

import MoreBottomSheet from "../components/BottomSheet/MoreBottomSheet"
import { ROUTER_ROUTE_MORE } from "../consts"
import { matchPath, withRouter } from "../services/router"
import {
  clearAllEntries,
  setEntriesFilterBy,
  setEntriesSortBy
} from "../store/entries/actions"
import {
  selectEntriesFilterBy,
  selectEntriesSortBy
} from "../store/entries/selectors"
import { Dispatch } from "../store/types"

const stateToProps = (state, ownProps) => {
  return {
    show: !!matchPath(ownProps.location.pathname, { path: ROUTER_ROUTE_MORE }),
    filterBy: selectEntriesFilterBy(state),
    sortBy: selectEntriesSortBy(state)
  }
}

const dispatchToProps = (dispatch: Dispatch) => ({
  onOutsidePress: () => dispatch(goBack()),
  onSortByChange: sortBy => {
    dispatch(setEntriesSortBy(sortBy))
    dispatch(goBack())
  },
  onFilterByChange: filterBy => {
    dispatch(setEntriesFilterBy(filterBy))
    dispatch(goBack())
  },
  onClearAllPress: () => {
    dispatch(clearAllEntries())
    dispatch(goBack())
  }
})

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps
  )(MoreBottomSheet)
)
