import { ofType } from "@martin_hotell/rex-tils"
import moment from "moment"
import { ActionsObservable, StateObservable } from "redux-observable"
import { concat, from, merge, of } from "rxjs"
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap
} from "rxjs/operators"

import {
  MESSAGE_HOURS_LIMIT_EXCEDED,
  MESSAGE_NETWORK_CONNECTION_ERROR
} from "../../locale"
import { Entry, emptyEntry } from "../../schemas/entries"
import { COLORS } from "../../utils/colors"
import * as alertsActions from "../alerts/actions"
import { State } from "../reducers"
import { selectTasksIdsByDate } from "../tasks/selectors"
import { trackError, trackEvent } from "../tracking/actions"
import { Dependencies } from "../types"
import * as uiActions from "../ui/actions"
import * as entriesActions from "./actions"
import {
  selectAreHoursValidBeforeUpdateForCurrentDate,
  selectEntriesWithSubmittedHoursByCurrentDate,
  selectSelectedEntry,
  selectUpdatingHoursHaveChanged
} from "./selectors"

/**
 * Crete Entries
 */
export const createEntries = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(entriesActions.ENTRIES_CREATE),
    map(action => {
      const state = state$.value
      const { entries, from, to } = action.payload

      const startMoment = moment.utc(from)
      const endMoment = moment.utc(to)
      const daysNum = endMoment.diff(startMoment, "days") + 1
      const datesRange = Array.from({ length: daysNum }, (v, i) =>
        startMoment
          .clone()
          .add(i, "d")
          .valueOf()
      )

      const createdEntries = datesRange.reduce(
        (accEntries, date) => {
          // tasks within range
          const taskIdsInRange = selectTasksIdsByDate(state, date)

          // entries within range
          const entriesInRange = entries.allIds.reduce(
            (acc, entryId) =>
              entries.byId[entryId].date === date
                ? [...acc, entries.byId[entryId]]
                : acc,
            []
          )

          // find exisitng entires or create empty ones for all tasks in range
          const createdEntriesInRange = taskIdsInRange.reduce(
            (accEntriesInRange, taskId) => {
              const exisitnigEntry = entriesInRange.find(
                (entry: Entry) => entry.taskId === taskId
              )

              const newEntry = exisitnigEntry
                ? exisitnigEntry
                : emptyEntry(taskId, date)

              accEntriesInRange.allIds.push(newEntry.id)
              accEntriesInRange.byId[newEntry.id] = newEntry

              return accEntriesInRange
            },
            { byId: {}, allIds: [] }
          )

          return {
            byId: { ...accEntries.byId, ...createdEntriesInRange.byId },
            allIds: [...accEntries.allIds, ...createdEntriesInRange.allIds]
          }
        },
        { byId: {}, allIds: [] }
      )

      return entriesActions.Actions.setEntries(createdEntries)
    })
  )

export const validateEntriesHours = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(entriesActions.ENTRY_HOURS_VALIDATE),
    filter(() => selectAreHoursValidBeforeUpdateForCurrentDate(state$.value)),
    mapTo(entriesActions.Actions.saveEntryHours())
  )

export const notifyNotValidEntriesHours = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(entriesActions.ENTRY_HOURS_VALIDATE),
    filter(() => !selectAreHoursValidBeforeUpdateForCurrentDate(state$.value)),
    mergeMap(() => [
      entriesActions.Actions.unsetSelectedEntry(),
      alertsActions.Actions.showSnackbar({
        message: MESSAGE_HOURS_LIMIT_EXCEDED,
        color: COLORS.red
      })
    ])
  )

export const validateEntriesHouresBeforeUpdate = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(entriesActions.ENTRY_HOURS_UPDATE),
    filter(() => selectUpdatingHoursHaveChanged(state$.value)),
    mapTo(entriesActions.Actions.validateEntryHours())
    // map(() =>
    //   selectAreHoursValidBeforeUpdateForCurrentDate(state$.value)
    //     ? entriesActions.Actions.saveEntryHours()
    //     : merge(
    //         of(entriesActions.Actions.unsetSelectedEntry()),
    //         of(
    //           alertsActions.Actions.showSnackbar({
    //             message: MESSAGE_HOURS_LIMIT_EXCEDED,
    //             color: COLORS.red
    //           })
    //         )
    //       )
    // )
  )

/**
 * Finish updating entry
 */
export const updateEntry = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(entriesActions.ENTRY_HOURS_SAVE),
    filter(() => selectUpdatingHoursHaveChanged(state$.value)),
    switchMap(() => {
      const state = state$.value
      const entry = selectSelectedEntry(state)

      return concat(
        of(entriesActions.Actions.updateEntryHoursWithSelected()),
        of(entriesActions.Actions.unsetSelectedEntry()),
        from(firebaseApi.saveEntries([entry])).pipe(
          mergeMap(action => [
            trackEvent({
              action: "Entry Update",
              category: "Update Timesheet"
            }),
            uiActions.Actions.showUserChangesSaved()
          ])
        )
      )
    }),
    catchError(error => [
      trackError(error),
      alertsActions.Actions.showSnackbar({
        message: MESSAGE_NETWORK_CONNECTION_ERROR,
        color: COLORS.red
      })
    ])
  )

/**
 * Ignore updating entry if hours have not changed
 * @param {ActionsObservable} action$
 * @param {Store} store
 */
export const cancelUpdateEntry = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(entriesActions.ENTRY_HOURS_UPDATE),

    filter(() => !!selectSelectedEntry(state$.value)),
    filter(() => !selectUpdatingHoursHaveChanged(state$.value)),
    map(() => entriesActions.Actions.unsetSelectedEntry())
  )

/**
 * Clear all submitted entries for the current date
 * @param {ActionsObservable} action$
 * @param {Store} store
 */
export const clearAllEntries = (
  action$: ActionsObservable<entriesActions.Actions>,
  state$: StateObservable<State>,
  { firebaseApi }: Dependencies
) =>
  action$.pipe(
    ofType(entriesActions.ENTRIES_CLEAR_ALL),
    filter(
      () =>
        selectEntriesWithSubmittedHoursByCurrentDate(state$.value).length > 0
    ),
    switchMap(() => {
      const submittedEntries = selectEntriesWithSubmittedHoursByCurrentDate(
        state$.value
      )

      const clearObservable = merge(
        submittedEntries.map(entry =>
          entriesActions.Actions.clearEntryHours(entry.id)
        )
      )

      const requestObservable = from(
        firebaseApi.saveEntries(submittedEntries)
      ).pipe(
        map(action =>
          merge(
            of(
              trackEvent({
                action: "Entry Update",
                category: "Update Timesheet"
              })
            ),
            of(uiActions.Actions.showUserChangesSaved())
          )
        )
      )

      return concat(clearObservable, requestObservable)
    })
  )
