import { StringMap } from "@martin_hotell/rex-tils"

import { Project } from "../../schemas/projects"
import * as projectsActions from "./actions"

export type State = Readonly<{
  allIds: Array<string>
  byId: StringMap<Project>
}>

export const initialState: State = {
  byId: {},
  allIds: []
}

const reducer = (
  state: State = initialState,
  action: projectsActions.Actions
): State => {
  switch (action.type) {
    case projectsActions.PROJECTS_SET: {
      const { payload } = action

      return {
        ...state,
        ...payload
      }
    }

    case projectsActions.PROJECTS_UNSET: {
      return initialState
    }

    default:
      return state
  }
}

export default reducer
