import React, { SFC } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    overflow: "hidden",
    backgroundColor: "white"
  }
})

type Props = {
  style?: ViewStyle
}

const FullPanel: SFC<Props> = ({ style, children, ...other }) => {
  return <View style={[styles.container, style]}>{children}</View>
}

export default FullPanel
