import { createAction } from "@martin_hotell/rex-tils"

import services from "./services"

export type GA_EVENT = {
  category: string
  action: string
  label?: string
  value?: number
  nonInteraction?: boolean
}

export const TRACK_SCREEN = "tracking__TRACK_SCREEN"
export const TRACK_EVENT = "tracking__TRACK_EVENT"
export const TRACK_LINK = "tracking__TRACK_LINK"
export const TRACK_ERROR = "tracking__TRACK_ERROR"

export const trackScreen = (screen: string) => {
  services.pageview(screen)
  return createAction(TRACK_SCREEN, screen)
}

export const trackEvent = (gaEvent: GA_EVENT) => {
  services.event(gaEvent)
  return createAction(TRACK_EVENT, gaEvent)
}

export const trackLink = (label: string) => {
  services.outboundLink({ label }, () => {}) // empty callback

  return createAction(TRACK_LINK, label)
}

export const trackError = (description: string) => {
  services.exception({ description })

  return createAction(TRACK_ERROR, description)
}
