/* 
  Easing for Animated
  based on Material Design
*/
import { Easing } from "react-native"

export const easeOut = Easing.bezier(0, 0, 0.2, 1)

// same as standard
export const easeInOut = Easing.bezier(0.4, 0.0, 0.6, 1)
