import tinycolor from "tinycolor2"

export const COLORS = {
  // project colors
  blue: "#4660ee",
  purple: "#9a5aa8",
  red: "#e85668",
  lightBlue: "#33b5fa",
  cyan: "#40c1c0",
  green: "#4dcd85",
  // other colors
  white: "#ffffff",
  text: "#131313",
  lightText: tinycolor("#131313")
    .lighten(60)
    .toHexString(),
  grey: "#888888",
  lightGrey: "#f7f7f7"
}

export const DEFAULT_HEADER_COLOR = "green"

export const getColorString = (color: string) => tinycolor(color).toHexString()

// create the gradient color
export const getGradientColor = (color: string): string =>
  tinycolor(color)
    .spin(20)
    .toHexString()
