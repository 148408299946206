import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { User } from "../../schemas/user"

export const USER_SET = "user__SET"
export const USER_UNSET = "user__UNSET"

export const setUser = (user: User) => createAction(USER_SET, user)
export const unsetUser = () => createAction(USER_UNSET)

export const Actions = {
  setUser,
  unsetUser
}

export type Actions = ActionsUnion<typeof Actions>
