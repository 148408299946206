import React, { Component, ReactNode } from "react"
import { Animated, Dimensions, StyleSheet, View, ViewStyle } from "react-native"

import { easeInOut, easeOut } from "../utils/easing"

type Props = {
  children: ReactNode
  contentStyle?: ViewStyle
  in: boolean
  style?: ViewStyle
  timing?: { enter: number; exit: number }
}

type State = {
  show: boolean
}

const ENTER = 400
const EXIT = 300
const opacityRange = { inputRange: [0, 1], outputRange: [0, 0.5] }
const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "stretch"
  },
  mask: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "#000000"
  },
  content: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center"
  }
})

class Modal extends Component<Props, State> {
  state = { show: this.props.in }

  progress = new Animated.Value(this.props.in ? 1 : 0)

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.in === this.props.in) return
    if (nextProps.in) this._animateIn()
    else this._animateOut()
  }

  _animateIn() {
    this.setState({ show: true })
    Animated.timing(this.progress, {
      toValue: 1,
      duration: this.props.timing ? this.props.timing.enter : ENTER,
      easing: easeOut
    }).start()
  }

  _animateOut() {
    Animated.timing(this.progress, {
      toValue: 0,
      duration: this.props.timing ? this.props.timing.exit : EXIT,
      easing: easeInOut
    }).start(() => this.setState({ show: false }))
  }

  render() {
    // if (!this.state.show) return null;
    const slideRange = {
      inputRange: [0, 1],
      outputRange: [Dimensions.get("window").height, 0]
    }
    const maskOpacity = {
      opacity: this.progress.interpolate(opacityRange),
      transform: [{ translateY: 0 }]
    }
    const contentSlide = {
      transform: [{ translateY: this.progress.interpolate(slideRange) }]
    }
    return (
      <View
        style={[
          styles.root,
          this.props.style,
          !this.state.show && { display: "none" }
        ]}
      >
        <Animated.View style={[styles.mask, maskOpacity]} />
        <Animated.View
          style={[styles.content, contentSlide, this.props.contentStyle]}
        >
          {this.props.children}
        </Animated.View>
      </View>
    )
  }
}

export default Modal
