import { connect } from "react-redux"

import HoursMinutesInput from "../components/HoursMinutesInput"
import { setSelectedEntryHours } from "../store/entries/actions"
import { selectSelectedEntry } from "../store/entries/selectors"
import { Dispatch } from "../store/types"
import { selectEntryUpdateType } from "../store/ui/selectors"

const mapStateToProps = (state, ownProps) => {
  return function(state, ownProps) {
    return {
      selectedEntry: selectSelectedEntry(state),
      updateType: selectEntryUpdateType(state)
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onUpdate: (hours: number) => dispatch(setSelectedEntryHours(hours))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoursMinutesInput)
