import React, { PureComponent } from "react"
import { ScrollView, StyleSheet } from "react-native"

import { Month } from "../../schemas/days"
import FullPanel from "../FullPanel"
import Modal from "../Modal"
import CalendarDay from "./CalendarDay"
import CalendarDayEmpty from "./CalendarDayEmpty"
import CalendarLabel from "./CalendarLabel"
import CalendarLabels from "./CalendarLabels"
import CalendarMonth from "./CalendarMonth"
import CalendarWeek from "./CalendarWeek"

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: "stretch"
  },
  list: {
    flex: 1,
    paddingBottom: 100
  }
})

type Props = {
  months: Array<Month>
  onSelect: (timestamp: number) => void
  show: boolean
}

export default class Calendar extends PureComponent<Props> {
  componentDidMount() {
    this._scrollToBottom()
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.show && nextProps.show !== this.props.show)
      this._scrollToBottom()
  }

  _scrollView = null

  _scrollToBottom() {
    setTimeout(() => {
      if (this._scrollView) this._scrollView.scrollToEnd({ animated: false })
    })
  }

  _renderMonth = (month: Month) => (
    <CalendarMonth key={month.key} name={month.name}>
      <CalendarLabels>
        {month.labels.map((label, i) => (
          <CalendarLabel key={`${month.key}-${label}-${i}`} label={label} />
        ))}
      </CalendarLabels>

      {month.weeks.map((days, i: number) => (
        <CalendarWeek key={`${month.key}-${i}`}>
          {days.map((day, j) =>
            day ? (
              <CalendarDay
                key={day.date}
                date={day.date}
                todo={day.isWorkingDay && day.hours < day.requiredHours}
                onSelect={this.props.onSelect}
                isSelectable={day.date <= new Date().getTime()}
                isWorkingDay={day.isWorkingDay}
              />
            ) : (
              <CalendarDayEmpty key={j} />
            )
          )}
        </CalendarWeek>
      ))}
    </CalendarMonth>
  )

  render() {
    const { months } = this.props
    return (
      <Modal in={this.props.show}>
        <FullPanel style={styles.root}>
          <ScrollView ref={c => (this._scrollView = c)} style={styles.list}>
            {months.map(this._renderMonth)}
          </ScrollView>
        </FullPanel>
      </Modal>
    )
  }
}
