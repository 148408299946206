import * as navigationActions from "./actions"

export const initialState = {
  date: 0
}
export type State = typeof initialState

export const reducer = (
  state: State = initialState,
  action: navigationActions.Actions
): State => {
  switch (action.type) {
    case navigationActions.GO_TO_DATE: {
      const { payload: date } = action

      return {
        ...state,
        date
      }
    }

    default:
      return state
  }
}

export default reducer
