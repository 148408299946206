import { connect } from "react-redux"

import Alert from "../components/Alert"
import { Actions } from "../store/alerts/actions"

const stateToProps = ({ alerts: { alert } }) => ({ alert })

const dispatchToProps = dispatch => ({
  onOk: () => dispatch(Actions.hideAlert())
})

export default connect(
  stateToProps,
  dispatchToProps
)(Alert)
