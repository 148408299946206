import React, { SFC } from "react"
import { StyleSheet, TextStyle, View } from "react-native"

import BodyText from "./BodyText"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "stretch",
    padding: 32,
    backgroundColor: "white"
  }
})

type Props = {
  style?: TextStyle
}
const EmptyDay: SFC<Props> = (props: Props) => (
  <View style={[styles.container, props.style]}>
    <BodyText style={{ textAlign: "center" }}>
      Oops! It looks like you have no tasks.
    </BodyText>
  </View>
)

export default EmptyDay
