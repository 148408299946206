import React, { PureComponent } from "react"

import LogIn from "../components/LogIn"

type Props = {
  onMount: () => void
  onLogin: () => void
}

export default class LoginScreen extends PureComponent<Props> {
  componentDidMount() {
    this.props.onMount()
  }
  render() {
    return <LogIn {...this.props} />
  }
}
