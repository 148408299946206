import * as settingsActions from "./actions"

export const initialState = {
  notifications: true
}
export type State = typeof initialState

export const reducer = (
  state: State = initialState,
  action: settingsActions.Actions
): State => {
  switch (action.type) {
    case settingsActions.SETTINGS_SET: {
      const { payload } = action

      return {
        notifications: payload["notifications"]
      }
    }
    case settingsActions.UPDATE_SETTINGS: {
      const { payload } = action

      return {
        ...state,
        ...payload
      }
    }
    default:
      return state
  }
}

export default reducer
