import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { State } from "./reducer"

/* Action Types */
export const TASKS_SET = "tasks__SET"
export const TASKS_UNSET = "tasks__UNSET"

export const setTasks = (payload: State) => createAction(TASKS_SET, payload)
export const unsetTasks = () => createAction(TASKS_UNSET)

export const Actions = {
  setTasks,
  unsetTasks
}

export type Actions = ActionsUnion<typeof Actions>
