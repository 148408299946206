import { createSelector } from "reselect"

import { State } from "../reducers"

const selectById = (state: State) => state.projects.byId
const selectId = (state: State, id: string) => id

/**
 * Returns project by given id
 */
export const selectProjectById = createSelector(
  selectById,
  selectId,
  (byId, id) => byId[id]
)

/**
 * Returns all projects in byId object
 */
export const selectProjects = (state: State) => selectById(state)

/**
 * Returns project color by given id
 */
export const selectProjectColorById = createSelector(
  selectProjectById,
  project => project.color
)
