import { connect } from "react-redux"

import TaskList from "../components/TaskList"
import { selectEntriesWithProjectIdsByCurrentDate } from "../store/entries/selectors"
import { selectHasData } from "../store/sources/selectors"

const mapStateToProps = state => {
  return {
    entries: selectEntriesWithProjectIdsByCurrentDate(state),
    hasData: selectHasData(state)
  }
}

export default connect(mapStateToProps)(TaskList)
