import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

export const FETCH = "sources__FETCH"
export const REFRESH = "sources__REFRESH"
export const CHECK_IF_FRESH = "sources__CHECK_IF_FRESH"
export const IS_FETCHING_SET = "sources.isFetching__SET"
export const LAST_FETCHED_SET = "sources.lastFetched__SET"
export const DATA_FROM_SET = "sources.dataFrom__SET"
export const DATA_TO_SET = "sources.dateTo__SET"
export const UNSET = "sources__UNSET"

/**
 * IS_FETCHING_SET
 */
export const setIsFetching = (isFetching: boolean = false) =>
  createAction(IS_FETCHING_SET, isFetching)

/**
 * LAST_FETCHED_SET
 */
export const setLastFetched = (lastFetched: number) =>
  createAction(LAST_FETCHED_SET, lastFetched)

/**
 * CHECK_IF_FRESH
 */
export const checkIfFresh = (forceRefresh: boolean = false) =>
  createAction(CHECK_IF_FRESH, forceRefresh)

/**
 * REFRESH
 */
export const refresh = () => createAction(REFRESH)

/**
 * FETCH
 */
export const fetch = (dataFrom: number, dataTo: number) =>
  createAction(FETCH, {
    dataFrom,
    dataTo
  })

export const setDataFrom = (dataFrom: number) =>
  createAction(DATA_FROM_SET, dataFrom)

export const setDataTo = (dataTo: number) => createAction(DATA_TO_SET, dataTo)
export const unsetSources = () => createAction(UNSET)

export const Actions = {
  checkIfFresh,
  setIsFetching,
  setLastFetched,
  refresh,
  fetch,
  setDataFrom,
  setDataTo,
  unsetSources
}

export type Actions = ActionsUnion<typeof Actions>
