import React from "react"
import { StyleSheet, View } from "react-native"

import { COLORS } from "../../utils/colors"
import BodyText from "../BodyText"

const styles = StyleSheet.create({
  container: {
    flexBasis: 28,
    justifyContent: "center",
    alignItems: "center",
    height: 52
  },
  text: {
    color: COLORS.grey
  }
})

type Props = { label: string }

export default function CalendarLabel({ label }: Props) {
  return (
    <View style={styles.container}>
      <BodyText style={styles.text}>{label}</BodyText>
    </View>
  )
}
