import { connect } from "react-redux"

import DayScreen from "../screens/DayScreen"
import { withRouter } from "../services/router"
import { trackScreen } from "../store/tracking/actions"
import { Dispatch } from "../store/types"

const stateToProps = () => ({})
const dispatchToProps = (dispatch: Dispatch) => ({
  onMount: () => dispatch(trackScreen("Home"))
})
export default withRouter(
  connect(
    stateToProps,
    dispatchToProps
  )(DayScreen)
)
