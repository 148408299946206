import React, { PureComponent } from "react"

import { commitSha1 } from "../../config"
import LightText from "../LightText"
import BottomSheet from "./BottomSheet"
import BottomSheetButton from "./BottomSheetButton"
import BottomSheetDivider from "./BottomSheetDivider"
import BottomSheetItem from "./BottomSheetItem"

type Props = {
  userName: string
  show: boolean
  onFeedbackPress: () => void
  onLogoutPress: () => void
  onOutsidePress: () => void
}

export default class MenuBottomSheet extends PureComponent<Props> {
  _handleFeedbackPressed = () => {
    this.props.onFeedbackPress()
  }

  _handleLogoutPressed = () => {
    this.props.onLogoutPress()
  }

  _handleOutsidePressed = () => {
    this.props.onOutsidePress()
  }
  render() {
    return (
      <BottomSheet
        in={this.props.show}
        onOutsidePress={this._handleOutsidePressed}
      >
        <BottomSheetItem>
          <BottomSheetButton
            text={`Log out (${this.props.userName})`}
            icon="account_box"
            onPress={this._handleLogoutPressed}
          />
        </BottomSheetItem>
        <BottomSheetDivider />
        <BottomSheetItem>
          <BottomSheetButton
            text="Send feedback"
            icon="feedback"
            onPress={this._handleFeedbackPressed}
          />
        </BottomSheetItem>
        <BottomSheetDivider />
        <BottomSheetItem>
          <LightText style={{ textAlign: "center" }}>
            Version: {commitSha1}
          </LightText>
        </BottomSheetItem>
      </BottomSheet>
    )
  }
}
