import React, { SFC } from "react"
import { StyleSheet, Text, TextStyle } from "react-native"

import { COLORS } from "../utils/colors"
import { webOnly } from "../utils/style"

type Props = {
  style?: TextStyle
}

const styles = StyleSheet.create({
  text: {
    fontFamily: "Roboto Light",
    fontSize: 15,
    backgroundColor: "transparent",
    color: COLORS.text,
    ...webOnly({
      WebkitFontSmoothing: "antialiased"
    })
  }
})

const BodyText: SFC<Props> = ({ style, ...other }) => {
  return <Text {...other} style={[styles.text, style]} />
}

export default BodyText
