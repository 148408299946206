import moment from "moment"
import { createSelector } from "reselect"

import { MAX_HOURS_PER_DAY } from "../../consts"
import { Day, DayWithHours, Month } from "../../schemas/days"
import { Entry } from "../../schemas/entries"
import {
  selectEntries,
  selectEntriesHoursForCurrentDate,
  selectSelectedEntryHours,
  selectSelectedEntryOriginalHours
} from "../entries/selectors"
import { selectCurrentDate } from "../navigation/selectors"
import { State } from "../reducers"

const selectById = (state: State) => state.days.byId
const selectId = (state: State, id: number): number => id

/**
 * Get days
 * @param {State} state
 */
export const selectDays = (state: State) => selectById(state)

/**
 * Select day by id
 */
export const selectDayById = createSelector(
  selectById,
  selectId,
  (byId, id): Day => byId[id]
)

/**
 * Select days and calculate number of sumbited hours
 */
export const selectDaysWithHours = createSelector(
  selectDays,
  selectEntries,
  (byId, entries): Array<DayWithHours> =>
    Object.values(byId).map((day: Day) => ({
      ...day,
      hours: Object.values(entries).reduce(
        (totalHours: number, entry: Entry) =>
          entry.date === day.date ? totalHours + entry.hours : totalHours,
        0
      )
    }))
)

/**
 * Get days grouped by month
 * @param {State} state
 */
export const selectDaysByMonth = createSelector(
  selectDaysWithHours,
  (days): Month[] =>
    days.reduce(
      (mem, day, index) => {
        const d = moment.utc(day.date)
        const month = d.format("MMMM")
        const year = d.format("YYYY")
        const week = d.isoWeek()
        const dayIndex = d.isoWeekday()
        const key = `${year}-${month}`

        if (!(key in mem.keys)) {
          mem.results.push({
            name: `${month} ${year}`,
            key,
            weeks: [],
            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
          })
          mem.keys[key] = { index: mem.results.length - 1, weekNums: [] }
        }

        let weekIndex = mem.keys[key].weekNums.indexOf(week)
        if (weekIndex === -1) {
          mem.keys[key].weekNums.push(week)
          weekIndex = mem.keys[key].weekNums.indexOf(week)

          mem.results[mem.keys[key].index].weeks[weekIndex] = Array.from(
            { length: 7 },
            (v, i) => null
          )
        }

        mem.results[mem.keys[key].index].weeks[weekIndex][dayIndex - 1] = day

        return mem
      },
      { results: [], keys: {} }
    ).results
)

/**
 * Get required hours for date
 * @param {State} state
 * @param {Number} timestamp
 */
export const selectRequiredHoursForDate = createSelector(
  selectById,
  selectCurrentDate,
  (byId, date: number): number => byId[date] && byId[date].requiredHours
)

export const selectRemainingHoursForDate = createSelector(
  selectEntriesHoursForCurrentDate,
  selectSelectedEntryHours,
  selectSelectedEntryOriginalHours,
  selectRequiredHoursForDate,
  (
    entriesHours: number,
    selectedEntryHours: number,
    selectedEntryOriginalHours: number,
    requiredHours: number
  ): number =>
    Math.max(
      requiredHours -
        entriesHours -
        selectedEntryHours +
        selectedEntryOriginalHours,
      0
    )
)

export const selectMaxRemainingHoursForDate = createSelector(
  selectEntriesHoursForCurrentDate,
  selectSelectedEntryHours,
  selectSelectedEntryOriginalHours,
  (
    entriesHours: number,
    selectedEntryHours: number,
    selectedEntryOriginalHours: number
  ): number =>
    Math.max(
      MAX_HOURS_PER_DAY -
        entriesHours -
        selectedEntryHours +
        selectedEntryOriginalHours,
      0
    )
)
