import React, { PureComponent } from "react"
import { View, ViewStyle } from "react-native"

type Props = {
  colors: string[]
  style: ViewStyle
  start: [number, number]
  end: [number, number]
}

class Header extends PureComponent<Props> {
  static defaultProps = {
    start: [0, 0],
    end: [0, 1]
  }

  render() {
    const { colors, style, start, end, ...other } = this.props
    const direction = end[1] > start[1] ? "to bottom" : "to right"
    const bg = {
      backgroundImage: `linear-gradient(${direction}, ${colors[0]}, ${
        colors[1]
      })`
    }
    //@ts-ignore
    return <View {...other} style={[bg, style]} />
  }
}

export default Header
