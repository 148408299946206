import moment from "moment"

import { AbsenceRequest, CalendarException } from "../../schemas/user"
import { State } from "../reducers"

// create a match function for a date withing CalendarException range
const createRangeMatch = (date: number) => ({
  startDate,
  endDate
}: CalendarException | AbsenceRequest) => date >= startDate && date <= endDate

const selectUser = (state: State) => state.user

export const selectUserName = (state: State) => selectUser(state).displayName

// get the working pattern ie. working day and working hours per week day (0 = sunday)
export const selectWorkingPattern = (state: State) =>
  selectUser(state).workingPattern

// check for a matching companyCalendarException or calendarException
//TODO: DO NOT RETURN TWO DIFFERENT OBJECTS LIKE THAT !!!
export const selectCalendarExceptionByDate = (date: number, state: State) => {
  const isWithinRange = createRangeMatch(date)
  const {
    companyCalendarExceptions: company,
    absenceRequests: requests
  } = selectUser(state)
  const companyException = company && company.find(isWithinRange)
  const absenceRequest = requests && requests.find(isWithinRange)
  return (
    companyException ||
    (absenceRequest && absenceRequest.isApproved ? absenceRequest : null)
  )
}

/**
 * Returns the last completed date, but at least a month ago
 */
export const selectEarliestDate = (state: State) => {
  const completedDate = selectUser(state).timesheetsCompletedUntil

  return moment
    .utc(completedDate)
    .startOf("month")
    .valueOf()
}
