import "firebase/functions"

import { isProduction } from "../../config"
import { isLocalhost } from "../../utils/localhost"
import firebase from "."

const functions = firebase.functions()

if (isLocalhost) functions.useFunctionsEmulator("http://localhost:3010")

export { functions }
