import React from "react"
import { StyleSheet, Text, View } from "react-native"
import { connect } from "react-redux"

import { selectProjectById } from "../store/projects/selectors"
import { COLORS } from "../utils/colors"

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: 72,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 12,
    backgroundColor: COLORS.lightGrey
  },
  text: {
    fontFamily: "Roboto Condensed",
    fontSize: 14
  }
})

type Props = { title: string }

function TaskListSubheader({ title }: Props) {
  return (
    <View style={styles.root}>
      <Text style={styles.text}>{title.toUpperCase()}</Text>
    </View>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps
  const project = selectProjectById(state, projectId)

  return {
    title: project.name
  }
}

export default connect(mapStateToProps)(TaskListSubheader)
