import { AuthStatus } from "./consts"
import { guid } from "./utils"
import { completeRedirect, signIn } from "./window"

export type AuthResult = {
  token: string
  status: AuthStatus
}

export interface IProvider {
  buildAuthorizeUrl(): string
  extractError(redirectUrl: string): Error | undefined
  extractToken(redirectUrl: string): string
  getSignOutUrl(redirectUrl: string): string
}

export interface IAuthenticationService {
  acquireTokenAsync(
    provider: IProvider,
    localWindow?: Window
  ): Promise<AuthResult>
  completePartialSignIn(
    provider: IProvider,
    redirectUrl: string
  ): Promise<AuthResult>
  revokeTokenAsync(provider: IProvider, uid: string): Promise<Boolean>
}

const authService: IAuthenticationService = {
  async acquireTokenAsync(provider: IProvider) {
    try {
      // Create unique request key
      const requestKey = `time-auth-request-key-${guid()}`
      const { token, status } = await signIn(requestKey, provider)

      return { token, status }
    } catch (error) {
      return error
    }
  },
  async completePartialSignIn(provider: IProvider, redirectUrl: string) {
    try {
      const { token, status } = await completeRedirect(redirectUrl, provider)

      return { token, status }
    } catch (error) {
      return error
    }
  },
  async revokeTokenAsync(provider: IProvider, uid: string) {
    try {
      await fetch(provider.getSignOutUrl(uid), { mode: "cors", method: "GET" })
      return true
    } catch {
      return false
    }
  }
}

export default authService
