import { replace } from "connected-react-router"
import { connect } from "react-redux"

import Calendar from "../components/Calendar/Calendar"
import { FLOATING_BUTTON_TYPE_CALENDAR, ROUTER_ROUTE_CALENDAR } from "../consts"
import { matchPath, withRouter } from "../services/router"
import { selectDaysByMonth } from "../store/days/selectors"
import { State } from "../store/reducers"
import { Dispatch } from "../store/types"
import { setFloatingButtonType } from "../store/ui/actions"
import { makeDayPath } from "../utils/routing"

const stateToProps = (
  state: State,
  ownProps: { location: { pathname: any } }
) => ({
  months: selectDaysByMonth(state),
  show: !!matchPath(ownProps.location.pathname, { path: ROUTER_ROUTE_CALENDAR })
})

const dispatchToProps = (dispatch: Dispatch) => ({
  onSelect: (timestamp: number) => {
    dispatch(replace(makeDayPath(timestamp)))
    dispatch(setFloatingButtonType(FLOATING_BUTTON_TYPE_CALENDAR))
  }
})

export default withRouter(
  connect(
    stateToProps,
    dispatchToProps
  )(Calendar)
)
