import React from "react"
import { connect } from "react-redux"

import {
  ENTRY_UPDATE_TYPE_NONE,
  FLOATING_BUTTON_TYPE_CALENDAR
} from "../../consts"
import { updateEntryHours } from "../../store/entries/actions"
import { selectSelectedEntryProjectColor } from "../../store/entries/selectors"
import { State } from "../../store/reducers"
import { Dispatch } from "../../store/types"
import {
  setEntryUpdateType,
  setFloatingButtonType
} from "../../store/ui/actions"
import { getColorString } from "../../utils/colors"
import Icon from "../Icon"
import FloatingActionButton from "./FloatingActionButton"

type Props = {
  onPress: () => void
  backgroundColor: string
}

function ConfirmFloatingActionButton(props: Props) {
  return (
    <FloatingActionButton
      onPress={props.onPress}
      backgroundColor={props.backgroundColor}
    >
      <Icon size={30} color="white" icon="done" />
    </FloatingActionButton>
  )
}

const stateToProps = (state: State) => {
  const backgroundColor = selectSelectedEntryProjectColor(state)

  return backgroundColor
    ? {
        backgroundColor: getColorString(backgroundColor)
      }
    : {}
}

const dispatchToProps = (dispatch: Dispatch) => ({
  onPress: () => {
    dispatch(updateEntryHours())
    dispatch(setEntryUpdateType(ENTRY_UPDATE_TYPE_NONE))
    dispatch(setFloatingButtonType(FLOATING_BUTTON_TYPE_CALENDAR))
  }
})

export default connect(
  stateToProps,
  dispatchToProps
)(ConfirmFloatingActionButton)
