import { StringMap } from "@martin_hotell/rex-tils"

import { Day } from "../../schemas/days"
import * as daysActions from "./actions"

export type State = Readonly<{
  byId: StringMap<Day>
  allIds: Array<number>
}>

export const initialState: State = {
  byId: {},
  allIds: []
}

export const reducer = (
  state: State = initialState,
  action: daysActions.Actions
): State => {
  switch (action.type) {
    case daysActions.DAYS_SET: {
      const { payload } = action

      return {
        ...state,
        ...payload
      }
    }
    case daysActions.DAYS_UNSET: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
