export const MAX_HOURS_PER_DAY: number = 24
/**
 * Routes
 */
export const ROUTER_ROUTE_HOME: string = "/"
export const ROUTER_ROUTE_DAY: string = "/day"
export const ROUTER_ROUTE_DAY_DATE: string = "/day/:date"
export const ROUTER_ROUTE_CALENDAR: string = "/calendar"

export const ROUTER_ROUTE_LOGIN: string = "/login"
export const ROUTER_ROUTE_MENU: string = "/menu"
export const ROUTER_ROUTE_MORE: string = "/more"
/**
 * Data
 */
export const DATA_STALE_THRESHOLD: Array<number | string> = [10, "minutes"]

export const ADMIN_PROJECT_ID: string = "3x74t2ue8xcddzabszuxl6nwr3033"

//Entry update types
export const ENTRY_UPDATE_TYPE_DRAG: string = "ENTRY_UPDATE_TYPE_DRAG"
export const ENTRY_UPDATE_TYPE_INPUT: string = "ENTRY_UPDATE_TYPE_INPUT"
export const ENTRY_UPDATE_TYPE_NONE: string = "ENTRY_UPDATE_TYPE_NONE"

/**
 * Floating Button Types
 */
export const FLOATING_BUTTON_TYPE_CALENDAR: string =
  "FLOATING_BUTTON_TYPE_CALENDAR"
export const FLOATING_BUTTON_TYPE_CONFIRM: string =
  "FLOATING_BUTTON_TYPE_CONFIRM"
export const FLOATING_BUTTON_TYPE_DAY: string = "FLOATING_BUTTON_TYPE_DAY"

export const FEEDBACK_FORM_URL: string =
  "https://goo.gl/forms/rQw0lCFx28tiBSkH3"

export const PROFILE_URL: string =
  "https://eu1.clarizen.com/Clarizen/ObjectDetails/UserProfile/"

/**
 * Entries filters
 */
export enum EntriesFilterBy {
  SHOW_ALL = "EntriesFilterBy.SHOW_ALL",
  SHOW_WITH_HOURS = "EntriesFilterBy.SHOW_WITH_HOURS"
}

/**
 * Entries sort order
 */
export enum EntriesSortBy {
  NAME = "EntriesSortBy.NAME",
  RECENT = "EntriesSortBy.RECENT"
}
