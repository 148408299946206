import { ofType } from "@martin_hotell/rex-tils"
import moment from "moment"
import { ActionsObservable, StateObservable } from "redux-observable"
import { map } from "rxjs/operators"

import { Day } from "../../schemas/days"
import { NormalizedData } from "../../schemas/sources"
import { CalendarException, WorkingPattern } from "../../schemas/user"
import { selectEntryDates } from "../entries/selectors"
import { State } from "../reducers"
import {
  selectCalendarExceptionByDate,
  selectWorkingPattern
} from "../user/selectors"
import * as daysActions from "./actions"

/**
 * Create days and return data in normalized format
 */
export const createDays = (
  action$: ActionsObservable<daysActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(daysActions.DAYS_CREATE),
    map(action => {
      const state = state$.value
      const workingPatterns: Array<WorkingPattern> = selectWorkingPattern(state)
      const entryDates: Array<number> = selectEntryDates(state)

      const createdDays: NormalizedData<number> = entryDates.reduce(
        (
          accDays: NormalizedData<number>,
          date: number
        ): NormalizedData<number> => {
          if (!(date in accDays.byId)) {
            // create Day if not existing
            const pattern =
              workingPatterns && workingPatterns.length > 0
                ? workingPatterns[moment(date).day()]
                : { isWorkingDay: true, totalWorkingHours: 8 }

            const exception = selectCalendarExceptionByDate(
              date,
              state
            ) as CalendarException

            const isWorkingDay = exception
              ? exception.isWorkingDay === true
              : pattern.isWorkingDay

            const requiredHours = !isWorkingDay
              ? 0
              : exception
              ? exception.requiredHours
              : pattern.totalWorkingHours

            const day: Day = {
              date: date,
              isWorkingDay,
              requiredHours,
              type: exception && exception.exceptionType
            }
            // save key
            accDays.byId[date] = day
            accDays.allIds.push(date)
          }

          return accDays
        },
        { allIds: [], byId: {} }
      )

      return daysActions.Actions.setDays(createdDays)
    })
  )
