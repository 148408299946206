import React, { Component } from "react"
import {
  Animated,
  Dimensions,
  PanResponder,
  PanResponderInstance,
  StyleSheet,
  View
} from "react-native"

import { ENTRY_UPDATE_TYPE_INPUT, MAX_HOURS_PER_DAY } from "../../consts"
import { Entry } from "../../schemas/entries"
import { getColorString } from "../../utils/colors"
import { easeOut } from "../../utils/easing"
import { createShadowElevation } from "../../utils/elevation"
import { roundToNearest } from "../../utils/numbers"
import ColorBar from "../ColorBar"

type Props = {
  color: string
  entry: Entry
  onUpdate: (hours: number) => void
  onUpdateDragStart: (entry: Entry) => void
  onUpdateDragStop: () => void
  onUpdateInputStart: (entry: Entry) => void
  onUpdateInputStop: () => void
  updateType: string
  remainingHours: number
  maxRemainingHours: number
  registerTapHandler: (callback: (() => void) | null) => void
}
type State = { isActive: boolean }

const FINE = 0.1 // threshold velocity for fine movement
const FLICK = 0.8 // threshold velocity for a flick
const K_FINE = 0.8 // multiplier for slow drag
const K_COARSE = 1.6 // multiplier for fast drag
const isHorizontal = ({ vx, vy, dx, dy }) => {
  const dist = Math.sqrt(dy * dy + dx * dx)
  const val = dy / dist
  return Math.abs(Math.asin(val) * (180 / Math.PI)) < 30
}
const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "white"
  },
  shadow: {
    ...StyleSheet.absoluteFillObject,
    // shadowColor: 'black',
    // shadowOpacity: 0.2,
    // shadowOffset: { width: 0, height: 2 },
    // shadowRadius: 10,
    ...createShadowElevation(12)
  },
  bar: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 2
  }
})

class TaskSlider extends Component<Props, State> {
  _assignedHours = this.props.entry.hours
  _maxHours = MAX_HOURS_PER_DAY
  _maxRemainingHours = MAX_HOURS_PER_DAY
  _remainingHours = MAX_HOURS_PER_DAY
  // animation props
  _shadowValue = new Animated.Value(0)
  _shouldDrag = false
  _panResponder: PanResponderInstance
  _progress = this.props.entry.hours / 8
  _sliderValue = new Animated.Value(Math.min(1, this.props.entry.hours / 8))
  _isDragging = false
  _lastTime = 0
  _width = Dimensions.get("window").width

  state = { isActive: false }

  constructor(props: Props) {
    super(props)
    this.updateState(props)
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !this.state.isActive || this.state.isActive !== nextState.isActive
  }

  updateState(props: Props) {
    this._maxRemainingHours = props.maxRemainingHours
    this._remainingHours = props.remainingHours
    this._assignedHours = props.entry.hours
    this._progress = props.entry.hours / 8
    this._updateMaxHours()
    this._updateSliderPosition()
  }

  _updateMaxHours() {
    const maxRemainingHours = this._maxRemainingHours + this._assignedHours

    if (this._assignedHours < 8) {
      this._maxHours = Math.min(8, maxRemainingHours)
    } else {
      this._maxHours = maxRemainingHours
    }
  }

  componentWillReceiveProps(props: Props) {
    this._remainingHours = props.remainingHours
    if (!this.state.isActive) this.updateState(props)
  }

  componentWillUpdate({ updateType }: Props, { isActive }: State) {
    if (
      isActive !== this.state.isActive ||
      updateType !== this.props.updateType
    ) {
      this._animateShadow(isActive || updateType === ENTRY_UPDATE_TYPE_INPUT)
    }
  }

  componentWillMount() {
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: this._registerTapHandler,
      onMoveShouldSetPanResponder: this._handleShouldRespond,
      onPanResponderGrant: this._handlePanGranted,
      onPanResponderMove: this._handlePanMove,
      onPanResponderRelease: this._handlePanEnd,
      onPanResponderTerminationRequest: this._handleTerminationRequest,
      onPanResponderTerminate: this._handleTermination
    })
  }

  _animateShadow(active: boolean) {
    Animated.timing(this._shadowValue, {
      toValue: active ? 1 : 0,
      duration: 300,
      easing: easeOut
    }).start()
  }

  // there is a ReactNative problem handling tap & pan, while allowing parent
  // ScrollView to handle pan in a specific direction, so we pass a prop through to
  // register/deregister a tap handler
  _registerTapHandler = () => {
    this.props.registerTapHandler(this._handleTap)
    return false // return false – we don't want to respond to taps
  }

  _handleTap = () => {
    if (this.props.updateType === ENTRY_UPDATE_TYPE_INPUT) {
      this.props.onUpdateInputStop()
    } else {
      this.props.onUpdateInputStart(this.props.entry)
    }
  }

  // we want all events so we can handle a tap
  _handleShouldRespond = (event: Object, { vx, vy, dx, dy }) => {
    // de-register the tapHandler - a move will cancel the tap
    this.props.registerTapHandler(null)

    return isHorizontal({ vx, vy, dx, dy })
  }

  // the touch responder is granted
  _handlePanGranted = ({ nativeEvent: { timestamp } }) => {
    // set inital values
    this._lastTime = timestamp
    this._width = Dimensions.get("window").width
  }

  // another component/scroll wants a piece of the action
  _handleTerminationRequest = () => !this._isDragging

  _handleTermination = () => {
    this._isDragging = false
    this.setState({ isActive: false })
    this._progress = this._assignedHours / 8
    this._updateSliderPosition()
  }

  _initDrag = () => {
    this._isDragging = true
    this.setState({ isActive: true })
    this.props.onUpdateDragStart(this.props.entry)
  }

  _handlePanMove = (event, { vx, dx, dy }) => {
    const {
      nativeEvent: { timestamp }
    } = event
    const isFine = Math.abs(vx) < FINE
    if (!this._isDragging) this._initDrag()
    const k = isFine ? K_FINE : K_COARSE
    const delta = k * vx * (timestamp - this._lastTime) // delta per frame
    const maxProgress = this._maxHours / 8
    const newProgress = this._progress + delta / this._width
    this._progress = Math.max(
      0,
      newProgress <= maxProgress ? newProgress : this._progress
    )

    this._sliderValue.setValue(Math.min(1, this._progress))
    this._lastTime = timestamp
    this._updateHours(isFine)
  }

  _handlePanEnd = (event, { vx }) => {
    this._isDragging = false
    this.setState({ isActive: false })
    // check flick

    if (Math.abs(vx) > FLICK) {
      const maxflickHours = this._remainingHours + this._assignedHours
      const flickHours =
        this._assignedHours < maxflickHours ? maxflickHours : this._maxHours
      this._progress = vx > 0 ? flickHours / 8 : 0
    }
    this._updateHours()

    // set progress exactly from hours
    this._progress = this._assignedHours / 8
    this._updateSliderPosition(() => this.props.onUpdateDragStop())
  }

  _updateHours = (fine: boolean = true) => {
    const assignedHours = Math.min(
      roundToNearest(this._progress * 8, fine ? 0.25 : 1),
      this._maxHours
    )

    // store end values
    if (assignedHours !== this._assignedHours) {
      this.props.onUpdate(assignedHours)
      this._assignedHours = assignedHours
    }
  }

  _updateSliderPosition(callback = () => {}) {
    const toValue = Math.min(1, this._progress)

    Animated.spring(this._sliderValue, { toValue }).start(callback)
  }

  render() {
    const color = getColorString(this.props.color)
    const { panHandlers } = this._panResponder
    return (
      <View {...panHandlers} style={[styles.root]}>
        <Animated.View
          style={[styles.shadow, { opacity: this._shadowValue }]}
        />
        <ColorBar
          style={styles.bar}
          color={color}
          progress={1}
          animatedProgress={this._sliderValue}
        />
      </View>
    )
  }
}

export default TaskSlider
