import { ofType } from "@martin_hotell/rex-tils"
import {
  LOCATION_CHANGE,
  LocationChangeAction,
  replace
} from "connected-react-router"
import moment from "moment"
import { ActionsObservable, StateObservable } from "redux-observable"
import { filter, map, take } from "rxjs/operators"

import { ROUTER_ROUTE_DAY_DATE, ROUTER_ROUTE_HOME } from "../../consts"
import { matchPath } from "../../services/router"
import { getNextDate, getPrevDate } from "../../utils/days"
import { makeDayPath } from "../../utils/routing"
import { selectIsAuthenticated } from "../auth/selectors"
import { State } from "../reducers"
import * as sourcesActions from "../sources/actions"
import * as navigationActions from "./actions"
import { selectCurrentDate, selectIsCurrentDateToday } from "./selectors"

// TODO: Add tracking to gotoDate
// trackEvent({ category: "Navigation", action: "Go to Date" })

export const goToPrevDay = (
  action$: ActionsObservable<navigationActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(navigationActions.GO_TO_PREV_DAY),
    map(() => {
      const timestamp = getPrevDate(selectCurrentDate(state$.value))
      return replace(makeDayPath(timestamp))
    })
  )

export const goToNextDay = (
  action$: ActionsObservable<navigationActions.Actions>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(navigationActions.GO_TO_NEXT_DAY),
    filter(() => !selectIsCurrentDateToday(state$.value)),
    map(() => {
      const timestamp = getNextDate(selectCurrentDate(state$.value))
      return replace(makeDayPath(timestamp))
    })
  )

/**
 * Navigate to date / respond to router
 * @param {ActionsObservable} action$
 */
export const navigateToDay = (
  action$: ActionsObservable<LocationChangeAction>
) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(action =>
      matchPath(action.payload.location.pathname, {
        path: ROUTER_ROUTE_DAY_DATE
      })
    ),
    map(action => {
      const match = matchPath(action.payload.location.pathname, {
        path: ROUTER_ROUTE_DAY_DATE
      })
      const timestamp = +moment.utc(match.params.date)

      return navigationActions.Actions.goToDate(timestamp)
    })
  )

/**
 * Navigate to today
 * @param {ActionsObservable} action$
 */
export const navigateToToday = (
  action$: ActionsObservable<LocationChangeAction>
) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(action =>
      matchPath(action.payload.location.pathname, {
        path: ROUTER_ROUTE_HOME,
        exact: true
      })
    ),
    map(action => {
      const timestamp = +moment.utc().startOf("day")

      return navigationActions.Actions.goToDate(timestamp)
    })
  )
