import React, { PureComponent, ReactNode } from "react"
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native"

import { createShadowElevation } from "../../utils/elevation"

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: 56,
    height: 56,
    backgroundColor: "#131313",
    borderRadius: 28,
    ...createShadowElevation(6)
  }
})

type Props = {
  onPress: () => void
  backgroundColor?: string
  children: ReactNode
}
export default class FloatingActionButton extends PureComponent<Props> {
  _handlePress = () => {
    this.props.onPress()
  }

  render() {
    const { backgroundColor, children }: Props = this.props

    return (
      <TouchableWithoutFeedback onPress={this._handlePress}>
        <View
          style={[styles.container, backgroundColor && { backgroundColor }]}
        >
          {children}
        </View>
      </TouchableWithoutFeedback>
    )
  }
}
