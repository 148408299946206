import { connect } from "react-redux"

import Snackbar from "../components/Snackbar"
import { Actions as AlertsActions } from "../store/alerts/actions"
import { Dispatch } from "../store/types"

const stateToProps = ({ alerts: { snackbar } }) => ({ snackbar })

const dispatchToProps = (dispatch: Dispatch) => ({
  hideSnackbar: () => dispatch(AlertsActions.hideSnackbar())
})

export default connect(
  stateToProps,
  dispatchToProps
)(Snackbar)
