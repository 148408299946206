import { firebaseFunctionsBase } from "../../../config"
import { IProvider } from ".."

const apiUrl: string = firebaseFunctionsBase
const baseUrl: string = window.location.origin

export const genericProvider: IProvider = {
  buildAuthorizeUrl() {
    return `${apiUrl}/auth/redirect?base_uri=${encodeURIComponent(baseUrl)}`
  },
  extractError(redirectUrl: string): Error | undefined {
    const errorMatch = redirectUrl.match(/error=([^&]+)/)
    if (!errorMatch) {
      return undefined
    }

    const errorReason = errorMatch[1]
    const errorDescriptionMatch = redirectUrl.match(/error_description=([^&]+)/)
    const errorDescription = errorDescriptionMatch
      ? errorDescriptionMatch[1]
      : ""
    return new Error(
      `Error during login. Reason: ${errorReason} Description: ${errorDescription}`
    )
  },
  extractToken(redirectUrl: string): string {
    let token: string = null
    const tokenMatch = redirectUrl.match(/firebaseToken=([^&]+)/)
    if (tokenMatch) {
      token = tokenMatch[1]
    }

    return token
  },

  getSignOutUrl(uid: string): string {
    return `${apiUrl}/auth/revoke?uid=${encodeURIComponent(`${uid}`)}`
  }
}
