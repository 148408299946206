import "./index.css"

import { ConnectedRouter } from "connected-react-router"
import Raven from "raven-js"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import App from "./App"
import * as Config from "./config"
import registerServiceWorker from "./registerServiceWorker"
import configureStore from "./store"

const { store, persistor, history } = configureStore()

if (Config.sentryID) {
  Raven.config(Config.sentryID, {
    environment: process.env.NODE_ENV
  }).install()
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)

registerServiceWorker()
