import React, { PureComponent, ReactNode } from "react"
import { TouchableWithoutFeedback, View } from "react-native"
import { ViewStyleProp } from "react-native/Libraries/StyleSheet/StyleSheet"

type Props = {
  onPress: () => void
  children: ReactNode
  disabled?: boolean
  style?: ViewStyleProp
}

export default class BottomBarButton extends PureComponent<Props> {
  _handlePress = () => {
    this.props.onPress()
  }

  render() {
    return (
      <View style={this.props.style}>
        <TouchableWithoutFeedback
          onPress={() => this._handlePress()}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </TouchableWithoutFeedback>
      </View>
    )
  }
}
