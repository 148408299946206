import { ofType } from "@martin_hotell/rex-tils"
import { ignoreElements, tap } from "rxjs/operators"

import { trackLink } from "../tracking/actions"
import * as generalActions from "./actions"
import { openURL } from "./services"

export const openLink = action$ =>
  action$.pipe(
    ofType(generalActions.OPEN_LINK),
    tap(({ payload }) => [trackLink(payload.label), openURL(payload.url)]),
    ignoreElements()
  )
