export function formatDoubleDecimalDigits(num: number): string {
  return num.toFixed(2).toLocaleString()
}

export function formatDoubleDigits(num: number): string {
  const str = String(num)
  return str.length < 2 ? ("0" + str).slice(-2) : str
}

export function convertToHHMM(num: number): string {
  const d = num % 1
  return `${formatDoubleDigits(Math.floor(num))}:${formatDoubleDigits(d * 60)}`
}

export function roundToNearest(
  numToRound: number,
  numToRoundTo: number
): number {
  return Math.round(numToRound / numToRoundTo) * numToRoundTo
}

export function getOrdinal(num: number, useHTML: boolean = false): string {
  let j = num % 10,
    k = num % 100,
    suffix = "th"
  if (j === 1 && k !== 11) {
    suffix = "st"
  }
  if (j === 2 && k !== 12) {
    suffix = "nd"
  }
  if (j === 3 && k !== 13) {
    suffix = "rd"
  }

  return num + suffix
}
