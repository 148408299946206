import { ActionsUnion, createAction } from "@martin_hotell/rex-tils"

import { State } from "./reducer"

export const PROJECTS_SET = "projects__SET"
export const PROJECTS_UNSET = "projects__UNSET"

export const setProjects = (payload: State) =>
  createAction(PROJECTS_SET, payload)

export const unsetProjects = () => createAction(PROJECTS_UNSET)

export const Actions = {
  setProjects,
  unsetProjects
}

export type Actions = ActionsUnion<typeof Actions>
