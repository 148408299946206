import React, { PureComponent } from "react"
import { StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native"

import { AlertPayload } from "../schemas/alerts"
import { COLORS } from "../utils/colors"
import BoldText from "./BoldText"
import Header from "./Header"
import Modal from "./Modal"

const styles = StyleSheet.create({
  root: {
    margin: 20,
    backgroundColor: "white"
  },
  title: {
    backgroundColor: "transparent",
    fontFamily: "Roboto Condensed",
    fontSize: 20,
    color: "white"
  },
  content: {
    padding: 20
  },
  text: {
    fontFamily: "Roboto Light",
    fontSize: 16,
    lineHeight: 22,
    color: COLORS.text
  },
  btn: {
    marginTop: 16
  }
})

type Props = {
  alert?: AlertPayload
  onOk: () => any
}

type State = {
  alert?: AlertPayload
  show: boolean
}
class Alert extends PureComponent<Props, State> {
  state: State = { alert: this.props.alert, show: !!this.props.alert }
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.alert !== this.props.alert)
      this.setState({
        alert: nextProps.alert || this.props.alert,
        show: !!nextProps.alert
      })
  }

  _handleOk = () => {
    this.props.onOk()
  }

  render() {
    const { text = "", title = "", action = "" } = this.state.alert || {}
    return (
      <Modal in={!!this.state.show}>
        <View style={styles.root}>
          <Header>
            <Text style={styles.title}>{title}</Text>
          </Header>
          <View style={styles.content}>
            <Text style={styles.text}>{text}</Text>
            <TouchableWithoutFeedback onPress={this._handleOk}>
              <View style={styles.btn}>
                <BoldText>{action}</BoldText>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </Modal>
    )
  }
}

export default Alert
